import { ChipProps } from '@nestoca/ui';
import { SelectOptionProps } from '@nestoca/ui';
import subYears from 'date-fns/subYears';

import { UnderwriterChecklistState } from 'store/underwriter-flow';
import {
    SelectOptions,
    YesNoOptional,
    Frequency,
    SemiMonthlyFrequency,
} from 'types';
import {
    LiabilityCreditTypeEnum,
    MPIInsurerResponse,
    ResidencyStatus,
    Partner,
    LiabilityPayOffTypesEnum,
} from 'types/applicant';
import {
    Investor,
    InvestorId,
    LIABILITY_STATUS,
    LineOfCreditRepaymentType,
    MortgageFeature,
    MortgageInsurer,
    Segment,
} from 'types/application';
import { DocumentStep } from 'types/documents';
import { LienOptions } from 'types/heloc';
import {
    AdvanceType,
    BuilderType,
    EstimatedPropertyValueMethod,
} from 'types/property';
import {
    isMin,
    isMax,
    isBeforeDate,
    isTodayOrFutureDate,
    formatMoney,
} from 'utils';
import { AllowedTerm } from 'utils/rate-filters';

import { PRODUCT_TRACKER_CODE } from './property';

import type { I18n } from '@lingui/core';
import type { TokenRole } from 'providers/auth/types';
import type { AddressSituation, RentingFrequency } from 'types/address';
import type {
    Lawyer,
    RequestForStatementPurpose,
    Solicitor,
} from 'types/agreement';
import type {
    MaritalStatus,
    Salutation,
    OtherIncomeType,
    RelationToMainApplicant,
    LiabilityPaymentMultiplier,
    LiabilityType,
    LiabilityCurrency,
    CreditScoreQuality,
    LiabilityCreditType,
    NumberOfApplicants,
    ApplicantPermission,
} from 'types/applicant';
import type {
    ApplicationState,
    CompoundPeriod,
    LiabilityStatus,
    PreApprovalType,
} from 'types/application';
import type {
    AssetInstitution,
    AssetTypes,
    FamilyRelationship,
} from 'types/asset';
import type { DocState } from 'types/documents';
import type { Lender } from 'types/lenders';
import type { Product } from 'types/product';
import type {
    Purpose,
    ConstructionType,
    FundPurpose,
    HeatingType,
    PropertyStyle,
    PropertyType,
    SewageType,
    PropertyTenure,
    WaterType,
    GarageType,
    GarageSizeType,
    PrequalPropertyType,
    InterestRateType,
    MortgageType,
    TermType,
    OtherPropertyType,
    Surface,
    MortgagePaymentFrequency,
    PurposeAfterTransaction,
    ProductTrackerCode,
} from 'types/property';

export const OverviewLabels = (i18n: I18n) => ({
    sectionlabel: 'overview',
    sublabels: [
        { label: 'term', formatter: (value) => i18n._(value) },
        { label: 'type', formatter: (value) => i18n._(value) },
        { label: 'rateHold', formatter: (value) => i18n._(value) },
        { label: 'commissionBPS', formatter: (value) => value },
    ],
});

export const DetailsLabels = () => ({
    sectionlabel: 'details',
    sublabels: [
        { label: 'rate', formatter: (value) => value },
        {
            label: 'ratePrimeVariance',
            formatter: (value) =>
                Number(value).toLocaleString(undefined, {
                    style: 'percent',
                    minimumFractionDigits: 2,
                }),
        },
    ],
});
const insurable = {
    true: 'insurable',
    false: 'notInsured',
};
export const RequirementsLabels = (i18n: I18n) => ({
    sectionlabel: 'requirements',
    sublabels: [
        {
            label: 'ltv',
            formatter: (value) => value,
        },
        { label: 'purpose', formatter: (value) => i18n._(value) },
        { label: 'insurance', formatter: (value) => i18n._(value) },
        {
            label: 'insurable',
            formatter: (value) => i18n._(insurable[value.toString()]),
        },
    ],
});

export const GuidelinesLabels = () => ({
    sectionlabel: 'guidelines',
    sublabels: [
        // TODO deprecated value use `amortizationMax` - subsection will show if key exists in obj
        {
            label: 'amortization',
            formatter: (value: number) => value,
        },
        {
            label: 'amortizationMax',
            formatter: (value: number) => value,
        },
        // TODO deprecated value use `propertyValueMax` - subsection will show if key exists in obj
        { label: 'maxPropertyValue', formatter: (value) => formatMoney(value) },
        { label: 'propertyValueMax', formatter: (value) => formatMoney(value) },
        // TODO deprecated value use `mortgageAmountMax` - subsection will show if key exists in obj
        {
            label: 'maxMortgageAmount',
            formatter: (value) => formatMoney(value),
        },
        {
            label: 'mortgageAmountMax',
            formatter: (value) => formatMoney(value),
        },
        { label: 'beacon', formatter: (value) => value },
        // TODO deprecated value use `gdsMax` - subsection will show if key exists in obj
        { label: 'gds', formatter: (value) => value },
        { label: 'gdsMax', formatter: (value) => value },
    ],
});

export const HelocRequirementsLabels = (i18n: I18n) => ({
    sectionlabel: 'requirements',
    sublabels: [
        {
            label: 'ltv',
            formatter: (value) => value,
        },
        { label: 'purpose', formatter: (value) => i18n._(value) },
    ],
});

export const HelocGuidelinesLabels = () => ({
    sectionlabel: 'guidelines',
    sublabels: [
        {
            label: 'creditLimitMax',
            formatter: (value) => formatMoney(value),
        },
        { label: 'beacon', formatter: (value) => value },
        { label: 'gds', formatter: (value) => value },
    ],
});

export const NotesLabels = () => ({
    sectionlabel: 'notes',
});

export const OCCUPATIONS_LABELS = (i18n: I18n) => ({
    MANAGER: i18n._(`Manager`),
    CLERICAL: i18n._(`Clerical`),
    LABOURER_TRADESPERSON: i18n._(`Labourer/Tradesperson`),
    RETIRED: i18n._(`Retired`),
    PROFESSIONAL: i18n._(`Professional`),
    SELF_EMPLOYED: i18n._(`Self Employed`),
    OTHER: i18n._(`Other`),
});

export const BORROWER_OCCUPATION = (i18n: I18n) => [
    {
        value: 'MANAGER',
        label: i18n._(`Manager`),
    },
    {
        value: 'CLERICAL',
        label: i18n._(`Clerical`),
    },
    {
        value: 'LABOURER_TRADESPERSON',
        label: i18n._(`Labourer/Tradesperson`),
    },
    {
        value: 'RETIRED',
        label: i18n._(`Retired`),
    },
    {
        value: 'PROFESSIONAL',
        label: i18n._(`Professional`),
    },
    {
        value: 'SELF_EMPLOYED',
        label: i18n._(`Self Employed`),
    },
    {
        value: 'OTHER',
        label: i18n._(`Other`),
    },
];

export const RESIDENTIAL_STATUS = (i18n: I18n) => [
    {
        value: 'RENTING',
        label: i18n._(`Rent`),
    },
    {
        value: 'OWNER',
        label: i18n._(`Own`),
    },
    {
        value: 'LIVING_WITH_PARENTS',
        label: i18n._(`Lives with Parents`),
    },
    {
        value: 'OTHER',
        label: i18n._(`Other`),
    },
];

export const OPERATING_AS = (i18n: I18n) => [
    {
        value: 'CORPORATION',
        label: i18n._(`corporation`),
    },
    {
        value: 'PARTNERSHIP',
        label: i18n._(`partnership`),
    },
    {
        value: 'SOLE_PROPRIETOR',
        label: i18n._(`soleProprietor`),
    },
];
export const PENSION_TYPE = (i18n: I18n) => [
    {
        value: 'OLD_AGE_SECURITY',
        label: i18n._(`pension.oldAgeSecurity`),
    },
    {
        value: 'CANADIAN_PENSION_PLAN',
        label: i18n._(`pension.canadianPensionPlan`),
    },
    {
        value: 'EMPLOYER',
        label: i18n._(`pension.employer`),
    },
    {
        value: 'OTHER',
        label: i18n._(`other`),
    },
];

export const INCOME_TYPE = (i18n: I18n) => [
    {
        value: 'SALARIED',
        label: i18n._(`Salaried`),
    },
    {
        value: 'HOURLY',
        label: i18n._(`Hourly`),
    },
    {
        value: 'HOURLY_COMMISSIONS',
        label: i18n._(`Hourly with commission`),
    },
    {
        value: 'COMMISSIONS',
        label: i18n._(`Commission`),
    },
    {
        value: 'SELF_EMPLOYED',
        label: i18n._(`Self employed`),
    },
    {
        value: 'PENSION',
        label: i18n._(`Pension`),
    },
    {
        value: 'NONE',
        label: i18n._(`No income / Parental leave / Sabbatical`),
    },
];
export const INSURERS = (i18n: I18n) =>
    (Object.keys(MortgageInsurer) as Array<keyof typeof MortgageInsurer>).map(
        (type) => ({
            value: type,
            label: i18n._(`insurer.${type}`),
        })
    );

export const PREVIOUS_MORTGAGE_INSURERS = (i18n: I18n) => {
    const insurers = (
        Object.keys(MortgageInsurer) as Array<keyof typeof MortgageInsurer>
    ).map((type) => ({
        value: type,
        label: i18n._(`insurer.${type}`),
    }));
    return [...insurers, { value: null, label: '-' }];
};

export const INSURANCE_TYPE = (i18n: I18n) => [
    {
        value: 'MORTGAGE_INSURANCE',
        label: i18n._('Mortgage Insurance'),
    },
    {
        value: 'LOW_RATIO',
        label: i18n._('Low Ratio'),
    },
    {
        value: 'BULK_LOW_RATIO',
        label: i18n._('Bulk Low Ratio'),
    },
];

export const INSURER_PROGRAM_OPTIONS = (i18n: I18n) =>
    ['ALT_A', 'FULL_SERVICE', 'NEW_TO_CANADA'].map((program) => ({
        value: program,
        label: i18n._(`insurerProgram.${program}`),
    }));

export const MARITAL_STATUS = [
    'SINGLE',
    'SEPARATED',
    'MARRIED',
    'DIVORCED',
    'COMMON_LAW',
    'WIDOWED',
    'SEPERATED',
    'OTHER',
] as const;
export const MARITAL_STATUS_OPTIONS = (i18n): SelectOptions<MaritalStatus> => [
    {
        value: 'SINGLE',
        label: i18n._(`Single`),
    },
    {
        value: 'MARRIED',
        label: i18n._(`Married`),
    },
    {
        value: 'DIVORCED',
        label: i18n._(`Divorced`),
    },
    {
        value: 'COMMON_LAW',
        label: i18n._(`Common Law`),
    },
    {
        value: 'WIDOWED',
        label: i18n._(`Widowed`),
    },
    {
        value: 'SEPERATED',
        label: i18n._(`Separated`),
    },
    {
        value: 'OTHER',
        label: i18n._(`Other`),
    },
];

export const INDUSTRY_SECTOR = (i18n: I18n) => [
    {
        value: 'BANK_FINANCE',
        label: i18n._(`Banking /finance`),
    },
    {
        value: 'MANUFACTURING',
        label: i18n._(`Manufacturing`),
    },
    {
        value: 'GOVERNMENT',
        label: i18n._(`Government`),
    },
    {
        value: 'HEALTH',
        label: i18n._(`Health`),
    },
    {
        value: 'RETAIL',
        label: i18n._(`Retail Sales`),
    },
    {
        value: 'HIGH_TECH',
        label: i18n._(`Hi-Tech`),
    },
    {
        value: 'EDUCATION',
        label: i18n._(`Education`),
    },
    {
        value: 'LEISURE_ENTERTAINMENT',
        label: i18n._(`Leisure /Entertainment`),
    },
    {
        value: 'SERVICES',
        label: i18n._(`Services`),
    },
    {
        value: 'TRANSPORTATION',
        label: i18n._(`Transport`),
    },
    {
        value: 'NATURAL_RESOURCES',
        label: i18n._(`naturalResources`),
    },
    {
        value: 'CONSTRUCTION',
        label: i18n._(`Construction`),
    },
    {
        value: 'FARMING',
        label: i18n._(`Farming`),
    },
];

export const DWELLING_TYPE = (i18n: I18n) => [
    {
        value: 'DETACHED',
        label: i18n._(`Detached`),
    },
    {
        value: 'SEMI_DETACHED',
        label: i18n._(`Semi-detached`),
    },
    {
        value: 'DUPLEX_DETACHED',
        label: i18n._(`Duplex detached`),
    },
    {
        value: 'DUPLEX_SEMI_DETACHED',
        label: i18n._(`Duplex semi-detached`),
    },
    {
        value: 'ROW_HOUSING',
        label: i18n._(`Row-housing`),
    },
    {
        value: 'APARTMENT_LOW_RISE',
        label: i18n._(`Apartment low-rise`),
    },
    {
        value: 'APARTMENT_HIGH_RISE',
        label: i18n._(`Apartment hi-rise`),
    },
    {
        value: 'MOBILE',
        label: i18n._(`Mobile`),
    },
    {
        value: 'TRI_PLEX_DETACHED',
        label: i18n._(`Triplex detached`),
    },
    {
        value: 'TRI_PLEX_SEMI_DETACHED',
        label: i18n._(`Triplex semi-detached`),
    },
    {
        value: 'STACKED',
        label: i18n._(`Staked`),
    },
    {
        value: 'MODULAR_HOME_DETACHED',
        label: i18n._(`Modular home detached`),
    },
    {
        value: 'MODULAR_HOME_SEMI_DETACHED',
        label: i18n._(`Modular home semi-detached`),
    },
    {
        value: 'FOUR_PLEX_DETACHED',
        label: i18n._(`Fourplex detached`),
    },
    {
        value: 'FOUR_PLEX_SEMI_DETACHED',
        label: i18n._(`Fourplex semi-detached`),
    },
];

export const DWELLING_STYLE = (i18n: I18n) => [
    {
        value: 'ONE_STOREY',
        label: i18n._(`One Storey`),
    },
    {
        value: 'BI_LEVEL',
        label: i18n._(`Bi-level`),
    },
    {
        value: 'TWO_STORY',
        label: i18n._(`Two Storey`),
    },
    {
        value: 'SPLIT_LEVEL',
        label: i18n._(`Split Level`),
    },
    {
        value: 'STOREY_AND_A_HALF',
        label: i18n._(`Storey and a half`),
    },
    {
        value: 'THREE_STOREY',
        label: i18n._(`Three Storey`),
    },
    {
        value: 'OTHER',
        label: i18n._(`other`),
    },
];

export const INTEREST_RATE_TYPE = (i18n: I18n) => [
    {
        value: 'FIXED',
        label: i18n._(`interestRateType.fixed`),
    },
    {
        value: 'VARIABLE',
        label: i18n._(`interestRateType.variable`),
    },
    {
        value: 'ADJUSTABLE',
        label: i18n._(`interestRateType.adjustable`),
    },
    {
        value: 'CAPPED_VARIABLE',
        label: i18n._(`interestRateType.cappedVariable`),
    },
];

export const ASSET_TYPE = (i18n: I18n): Record<AssetTypes, string> => ({
    SAVINGS: i18n._(`Savings`),
    TFSA: i18n._(`TFSA`),
    RRSP: i18n._(`RRSP`),
    NON_REGISTERED_INVESTMENTS: i18n._(`Non registered investments`),
    GIFT: i18n._(`Gift`),
    VEHICLE: i18n._(`Vehicle`),
    PROPERTY: i18n._(`Property`),
    OTHER: i18n._(`Other`),
    HELOC: i18n._(`HELOC`),
});

export const SOURCE_OF_EQUITY = (i18n: I18n) => [
    {
        value: 'SAVINGS',
        label: ASSET_TYPE(i18n).SAVINGS,
    },
    {
        value: 'TFSA',
        label: ASSET_TYPE(i18n).TFSA,
    },
    {
        value: 'RRSP',
        label: ASSET_TYPE(i18n).RRSP,
    },
    {
        value: 'NON_REGISTERED_INVESTMENTS',
        label: ASSET_TYPE(i18n).NON_REGISTERED_INVESTMENTS,
    },
    {
        value: 'GIFT',
        label: ASSET_TYPE(i18n).GIFT,
    },
    {
        value: 'VEHICLE',
        label: ASSET_TYPE(i18n).VEHICLE,
    },
    {
        value: 'PROPERTY',
        label: ASSET_TYPE(i18n).PROPERTY,
    },
    {
        value: 'OTHER',
        label: ASSET_TYPE(i18n).OTHER,
    },
];

export const BUILDING_TYPE = (i18n: I18n) => [
    {
        value: 'NEW_BUILDING',
        label: i18n._(`New building`),
    },
    {
        value: 'EXISTING_BUILDING',
        label: i18n._(`Existing building`),
    },
];

export const GARAGE_SIZE = (i18n: I18n) => [
    {
        value: 'SINGLE',
        label: i18n._('garageSize.single'),
    },
    {
        value: 'DOUBLE',
        label: i18n._('garageSize.double'),
    },
    {
        value: 'TRIPLE',
        label: i18n._('garageSize.triple'),
    },
    {
        value: 'NONE',
        label: i18n._('garageSize.none'),
    },
];

export const GARAGE_TYPE = (i18n: I18n) => [
    {
        value: 'DETACHED',
        label: i18n._(`Detached`),
    },
    {
        value: 'ATTACHED',
        label: i18n._(`Attached`),
    },
];

export const HEALING_TYPE = (i18n: I18n) => [
    {
        value: 'ELECTRIC',
        label: i18n._(`Electric`),
    },
    {
        value: 'FORCED_AIR_OIL_GAS_ELECTRIC',
        label: i18n._(`Forced Air/Gas/Oil/Electric`),
    },
    {
        value: 'HOT_WATER',
        label: i18n._(`Hot water heating`),
    },
    {
        value: 'OTHER',
        label: i18n._(`Other`),
    },
];
export const MORTGAGE_REPAYMENT_OPTIONS = (
    i18n: I18n
): SelectOptions<LineOfCreditRepaymentType> => [
    {
        value: LineOfCreditRepaymentType.LOC_REPAYMENT_5YEARS,
        label: i18n._(
            `5 years no principal repayment, 20 years principal repayment`
        ),
    },
    {
        value: LineOfCreditRepaymentType.LOC_REPAYMENT_10YEARS,
        label: i18n._(
            `10 years no principal repayment, 15 years principal repayment`
        ),
    },
    {
        value: LineOfCreditRepaymentType.LOC_REPAYMENT_UNDETERMINED,
        label: i18n._(`No predetermined principal repayment`),
    },
];

export const PROCESSING_INDICATOR = (i18n: I18n) => [
    {
        value: 'I',
        label: i18n._(`Application for Insurance`),
    },
    {
        value: 'L',
        label: i18n._(`Application for Loan Assessment (low ratio)`),
    },
];

export const OCCUPANCY_TYPE = (i18n: I18n) => [
    {
        value: 'OWNER_OCCUPIED',
        label: i18n._(`Owner occupied`),
    },
    {
        value: 'CONVERT_DUPLEX_TO_HOME',
        label: i18n._(`Convert duplex to home`),
    },
    {
        value: 'RENTAL',
        label: i18n._(`Rental / investment`),
    },
    {
        value: 'SELLING',
        label: i18n._(`Selling`),
    },
];

export const TENURE_TYPE = (i18n: I18n) => [
    {
        value: 'FREE_HOLD',
        label: i18n._(`Free hold`),
    },
    {
        value: 'LEASE_HOLD',
        label: i18n._(`Lease hold`),
    },
    {
        value: 'CONDO',
        label: i18n._(`Condo`),
    },
];

export const TENURE = (i18n: I18n) => [
    {
        value: 'PERMANENT',
        label: i18n._(`permanent`),
    },
    {
        value: 'ON_PROBATION',
        label: i18n._(`onProbation`),
    },
    {
        value: 'ON_CONTRACT',
        label: i18n._(`onContract`),
    },
];

export const LANGUAGE = (i18n: I18n): SelectOptions<'fr' | 'en'> => [
    {
        value: 'fr',
        label: i18n._(`French`),
    },
    {
        value: 'en',
        label: i18n._(`English`),
    },
];

export const LANGUAGE_UPPERCASE_OPTIONS = (
    i18n: I18n
): SelectOptions<'FR' | 'EN'> =>
    LANGUAGE(i18n).map((option) => ({
        ...option,
        value: option.value.toUpperCase() as 'FR' | 'EN',
    }));

export const COUNTRIES = (i18n: I18n) => [
    {
        value: 'CA',
        label: i18n._(`Canada`),
    },
    {
        value: 'US',
        label: i18n._(`United States`),
    },
];

export const HELOC_OPTIONS = (i18n: I18n) => [
    {
        value: 'HELOC_WITH',
        label: i18n._(`HELOC_WITH`),
    },
    {
        value: 'HELOC_WITHOUT',
        label: i18n._(`HELOC_WITHOUT`),
    },
];

export const VALUATION_METHOD_OPTIONS = (i18n: I18n) => [
    {
        value: EstimatedPropertyValueMethod.APPRAISED,
        label: i18n._('valuationMethod.appraised'),
    },
    {
        value: EstimatedPropertyValueMethod.ESTIMATED,
        label: i18n._('valuationMethod.estimated'),
    },
    {
        value: EstimatedPropertyValueMethod.PURCHASE_PRICE,
        label: i18n._('valuationMethod.purchasePrice'),
    },
];

export const VALUATION_METHOD_LABEL = (i18n: I18n) => ({
    [EstimatedPropertyValueMethod.APPRAISED]: i18n._(
        'valuationMethod.appraised'
    ),
    [EstimatedPropertyValueMethod.ESTIMATED]: i18n._(
        'valuationMethod.estimated'
    ),
    [EstimatedPropertyValueMethod.PURCHASE_PRICE]: i18n._(
        'valuationMethod.purchasePrice'
    ),
});

export const PROPERTY_LISTING_TYPE_OPTIONS = (i18n: I18n) => [
    { value: '', label: i18n._('None') },
    { value: 'MLS', label: 'MLS' },
    { value: 'FSBO', label: 'FSBO' },
];

export const INVESTOR_MAP = {
    [Investor.NATIONAL_BANK]: {
        id: Investor.NATIONAL_BANK,
        labelKey: 'lender.nationalBank',
    },
    [Investor.NATIONAL_BANK_UNINSURABLE]: {
        id: Investor.NATIONAL_BANK_UNINSURABLE,
        labelKey: 'lender.nationalBankUninsurable',
    },
    [Investor.TD]: { id: Investor.TD, labelKey: 'lender.td' },
    [Investor.LAURENTIAN_BANK]: {
        id: Investor.LAURENTIAN_BANK,
        labelKey: 'lender.laurentian',
    },
    [Investor.NESTO_WAREHOUSE]: {
        id: Investor.NESTO_WAREHOUSE,
        labelKey: 'lender.nestoWarehouse',
    },
    [Investor.PEOPLES_TRUST_COMPANY]: {
        id: Investor.PEOPLES_TRUST_COMPANY,
        labelKey: 'lender.peoplesTrustCompany',
    },
    [Investor.CMLS]: { id: Investor.CMLS, labelKey: 'investor.cmls' },
    [Investor.IG]: { id: Investor.IG, labelKey: 'lender.IG' },
};

export const INVESTOR_ID_TO_ENUM = {
    [InvestorId.NATIONAL_BANK]: Investor.NATIONAL_BANK,
    [InvestorId.TD]: Investor.TD,
    [InvestorId.NATIONAL_BANK_UNINSURABLE]: Investor.NATIONAL_BANK_UNINSURABLE,
    [InvestorId.LAURENTIAN_BANK]: Investor.LAURENTIAN_BANK,
    [InvestorId.IG]: Investor.IG,
    [InvestorId.NESTO_WAREHOUSE]: Investor.NESTO_WAREHOUSE,
    [InvestorId.PEOPLES_TRUST_COMPANY]: Investor.PEOPLES_TRUST_COMPANY,
    [InvestorId.CMLS]: Investor.CMLS,
};

export const INVESTOR_ID_TO_LABEL = {
    [InvestorId.NATIONAL_BANK]: 'lender.nationalBank',
    [InvestorId.TD]: 'lender.td',
    [InvestorId.NATIONAL_BANK_UNINSURABLE]: 'lender.nationalBankUninsurable',
    [InvestorId.NESTO_WAREHOUSE]: 'lender.nestoWarehouse',
    [InvestorId.PEOPLES_TRUST_COMPANY]: 'lender.peoplesTrustCompany',
    [InvestorId.CMLS]: 'investor.cmls',
};

export const MORTGAGE_FEATURE_OPTIONS = (i18n: I18n) => [
    { value: '', label: i18n._('None') },
    { value: MortgageFeature.MORE_RESTRICTIONS, label: i18n._('lowFeature') },
];

export const MIDDLE_OFFICE_SYNC_SECTIONS = (i18n: I18n) => ({
    subjectProperty: { label: i18n._('subjectProperty'), order: 1 },
    mortgageDetails: { label: i18n._('mortgageDetails'), order: 2 },
    mainBorrower: { label: i18n._('Main Borrower'), order: 3 },
    coBorrowers: { label: i18n._('coBorrowers'), order: 4 },
});

export const PAYMENT_INCREASE_PRIVILEGES_OPTIONS = (i18n: I18n) => [
    { value: 'INCREASE_PAYMENTS_NONE', label: i18n._('None') },
    { value: 'INCREASE_PAYMENTS_15', label: '15' },
    { value: 'INCREASE_PAYMENTS_20', label: '20' },
];

export const INSURER_SUBMISSION_SECTIONS = (i18n: I18n) => ({
    transaction: { label: i18n._(`Transaction`), order: 1 },
    financial: { label: i18n._(`Financial`), order: 2 },
    property: { label: i18n._(`Property`), order: 3 },
    mainBorrower: { label: i18n._(`Main Borrower`), order: 4 },
    coBorrowers: { label: i18n._(`coBorrowers`), order: 5 },
    escalation: { label: i18n._(`escalation`), order: 6 },
});

export const AGREEMENT_SECTIONS = (i18n: I18n) => ({
    borrowers: {
        label: i18n._(`agreement.borrowers.title`),
        url: 'borrowers',
        order: 1,
    },
    property: {
        label: i18n._(`agreement.property.title`),
        url: 'property',
        order: 2,
    },
    mortgage: {
        label: i18n._(`agreement.mortgage.title`),
        url: 'mortgage',
        order: 3,
    },
    commitment: {
        label: i18n._(`agreement.commitment`),
        url: 'commitmentFile',
        order: 4,
    },
    disclosureToBorrower: {
        label: i18n._(`agreement.disclosureToBorrower`),
        url: 'disclosureToBorrower',
        order: 5,
    },
    findersFee: {
        label: i18n._(`agreement.findersFee`),
        url: 'findersFeeDisclosure',
        order: 6,
    },
    amortizationSchedule: {
        label: i18n._(`agreement.amortizationSchedule`),
        url: 'amortizationSchedule',
        order: 7,
    },
    other: {
        label: i18n._(`agreement.otherDocs`),
        url: 'other',
        order: 8,
    },
});

export const REQUEST_FOR_STATEMENT_OPTIONS = (
    i18n: I18n
): SelectOptions<RequestForStatementPurpose> => [
    {
        value: 'DISCHARGE',
        label: i18n._(`discharge`),
    },
    {
        value: 'TRANSFER',
        label: i18n._(`transfer`),
    },
];

export const SOLICITOR_OPTIONS = (i18n: I18n): SelectOptions<Solicitor> => [
    {
        value: 'NOTARY',
        label: i18n._(`solicitor.notary`),
    },
    {
        value: 'FCT',
        label: i18n._(`solicitor.fct`),
    },
    {
        value: 'FNF',
        label: i18n._(`solicitor.fnf`),
    },
];

export const SOLICITOR_OR_FCT_OPTIONS = (
    i18n: I18n
): SelectOptions<Solicitor> => [
    {
        value: 'SOLICITOR',
        label: i18n._(`solicitor`),
    },
    {
        value: 'FCT',
        label: i18n._(`solicitor.fct`),
    },
];

export const SOLICITOR_OPTIONS_SUBMISSION_NOTES = (
    i18n: I18n
): SelectOptions<Lawyer> => [
    {
        value: 'LAWYER',
        label: i18n._(`solicitor.lawyer`),
    },
    {
        value: 'NOTARY',
        label: i18n._(`solicitor.notary`),
    },
    {
        value: 'FCT',
        label: i18n._(`solicitor.fct`),
    },
    {
        value: 'FNF',
        label: i18n._(`solicitor.fnf`),
    },
];

export const FIELD_META = (
    i18n: I18n,
    transactionTypeOptions: SelectOptions
) => ({
    transaction: {
        referenceNumber: {
            order: 1,
            type: 'text',
        },
        closingDate: {
            order: 2,
            type: 'date',
            rules: {
                validate: {
                    isTodayOrFutureDate: isTodayOrFutureDate(i18n),
                },
                required: i18n._('error.dateRequired'),
            },
            maxYear: new Date().getFullYear() + 10,
        },
        loanPurpose: {
            order: 3,
            type: 'select',
            options: transactionTypeOptions,
            search: true,
        },
        interestType: {
            order: 4,
            type: 'select',
            options: INTEREST_RATE_TYPE(i18n),
            search: true,
        },
        interestRate: {
            order: 5,
            type: 'percentage',
        },
        loanAmount: {
            order: 6,
            type: 'money',
            rules: {
                validate: {
                    min: isMin(i18n, 0),
                    max: isMax(i18n, 9999999),
                },
            },
        },
        outstandingBalance: {
            order: 7,
            type: 'money',
            mandatory: false, // Ignore the BE flag since validation depends on the current `loanPurpose` value
        },
        ltvRatio: {
            order: 8,
            type: 'percentage',
        },
        loanTerm: {
            order: 9,
            type: 'yearsMonths',
        },
        amortization: {
            order: 10,
            type: 'yearsMonths',
        },
        blendedAmortization: {
            order: 11,
            type: 'toggle',
        },
        estimatedAnnualNonMortgageRelatedPayments: {
            order: 12,
            type: 'money',
        },
        premiumCharged: {
            order: 13,
            type: 'toggle',
        },
        previousSaleClosingDate: {
            order: 14,
            type: 'date',
            rules: {
                validate: {
                    isBeforeDate: isBeforeDate(i18n),
                },
            },
        },
        previousSalePrice: {
            order: 15,
            type: 'money',
            rules: {
                validate: {
                    min: isMin(i18n, 0),
                    max: isMax(i18n, 9999999),
                },
            },
        },
        improvementsMadeToProperty: {
            order: 16,
            type: 'toggle',
        },
        improvedPropertyValue: {
            order: 17,
            type: 'money',
        },
        isLineOfCredit: {
            order: 18,
            type: 'toggle',
        },
        lineOfCreditRepaymentType: {
            order: 19,
            type: 'select',
            search: true,
            options: MORTGAGE_REPAYMENT_OPTIONS(i18n),
        },
        processingIndicator: {
            order: 20,
            type: 'select',
            search: true,
            options: PROCESSING_INDICATOR(i18n),
        },
    },
    financial: {
        totalQualifyingIncome: {
            order: 1,
            type: 'money',
        },
        totalObligations: {
            order: 2,
            type: 'money',
        },
        gds: {
            order: 3,
            type: 'percentage',
        },
        tds: {
            order: 4,
            type: 'percentage',
        },
        assets: {
            order: 5,
            type: 'assets',
        },
        annualShelterPayment: {
            order: 6,
            type: 'money',
        },
    },
    property: {
        address: {
            order: 1,
            type: 'address',
        },
        addressStreetType: {
            order: 2,
            type: 'text',
        },
        lendingValue: {
            order: 3,
            type: 'money',
        },
        sellingPrice: {
            order: 4,
            type: 'money',
        },
        marketValue: {
            order: 5,
            type: 'money',
        },
        taxes: {
            order: 6,
            type: 'money',
        },
        occupancy: {
            order: 7,
            type: 'select',
            search: true,
            options: OCCUPANCY_TYPE(i18n),
        },
        tenure: {
            order: 8,
            type: 'select',
            search: true,
            options: TENURE_TYPE(i18n),
        },
        dwellingStyle: {
            order: 9,
            type: 'select',
            search: true,
            options: DWELLING_STYLE(i18n),
        },
        dwellingType: {
            order: 10,
            type: 'select',
            search: true,
            options: DWELLING_TYPE(i18n),
        },
        buildingCode: {
            order: 11,
            type: 'select',
            search: true,
            options: BUILDING_TYPE(i18n),
        },
        propertyAge: {
            order: 12,
            type: 'yearsMonths',
        },
        unitsCount: {
            order: 13,
            type: 'number',
            integer: true,
        },
        buildingSize: {
            order: 14,
            type: 'number',
            integer: true,
        },
        lotSize: {
            order: 15,
            type: 'number',
            integer: true,
        },
        heatingType: {
            order: 16,
            type: 'select',
            options: HEALING_TYPE(i18n),
        },
        heatingAnnualPayment: {
            order: 17,
            type: 'money',
        },
        garageType: {
            order: 18,
            type: 'select',
            search: true,
            options: GARAGE_TYPE(i18n),
            isClearable: true,
        },
        garageSize: {
            order: 19,
            type: 'select',
            search: true,
            options: GARAGE_SIZE(i18n),
            isClearable: true,
        },
        environmentalHazard: {
            order: 20,
            type: 'toggle',
        },
        mlsListed: {
            order: 21,
            type: 'toggle',
        },
        condoFees: {
            order: 22,
            type: 'money',
        },
    },
    mainBorrower: {
        firstName: {
            order: 1,
            type: 'text',
        },
        lastName: {
            order: 2,
            type: 'text',
        },
        dateOfBirth: {
            order: 3,
            type: 'date',
            rules: {
                validate: {
                    isBeforeDate: isBeforeDate(i18n),
                },
            },
        },
        phone: {
            order: 4,
            type: 'phone',
        },
        currentAddress: {
            order: 5,
            type: 'address',
        },
        currentResidentialStatus: {
            order: 6,
            type: 'select',
            search: true,
            options: RESIDENTIAL_STATUS(i18n),
        },
        durationAtAddress: {
            order: 7,
            type: 'yearsMonths',
        },
        maritalStatus: {
            order: 8,
            type: 'select',
            search: true,
            options: MARITAL_STATUS_OPTIONS(i18n),
        },
        numberOfDependents: {
            order: 9,
            type: 'number',
        },
        language: {
            order: 10,
            type: 'select',
            search: false,
            options: LANGUAGE(i18n),
        },
        occupation: {
            order: 11,
            type: 'select',
            search: true,
            options: BORROWER_OCCUPATION(i18n),
        },
        industry: {
            order: 12,
            type: 'select',
            search: true,
            options: INDUSTRY_SECTOR(i18n),
        },
        durationOfService: {
            order: 13,
            type: 'yearsMonths',
        },
        durationWithCurrentEmployer: {
            order: 14,
            type: 'yearsMonths',
        },
        incomeType: {
            order: 15,
            type: 'select',
            search: true,
            options: INCOME_TYPE(i18n),
        },
        hasBankAccounts: {
            order: 16,
            type: 'toggle',
        },
        hasSavingsAccounts: {
            order: 17,
            type: 'toggle',
        },
        totalQualifyingIncome: {
            order: 18,
            type: 'money',
        },
        employerName: {
            order: 19,
            type: 'text',
        },
    },
    coBorrowers: {
        firstName: {
            order: 1,
            type: 'text',
        },
        lastName: {
            order: 2,
            type: 'text',
        },
        dateOfBirth: {
            order: 3,
            type: 'date',
            rules: {
                validate: {
                    isBeforeDate: isBeforeDate(i18n),
                },
            },
        },
        phone: {
            order: 4,
            type: 'phone',
        },
        currentAddress: {
            order: 5,
            type: 'address',
        },
        currentResidentialStatus: {
            order: 6,
            type: 'select',
            search: true,
            options: RESIDENTIAL_STATUS(i18n),
        },
        durationAtAddress: {
            order: 7,
            type: 'yearsMonths',
        },
        maritalStatus: {
            order: 8,
            type: 'select',
            search: true,
            options: MARITAL_STATUS_OPTIONS(i18n),
        },
        numberOfDependents: {
            order: 9,
            type: 'number',
        },
        language: {
            order: 10,
            type: 'select',
            search: false,
            options: LANGUAGE(i18n),
        },
        occupation: {
            order: 11,
            type: 'select',
            search: true,
            options: BORROWER_OCCUPATION(i18n),
        },
        industry: {
            order: 12,
            type: 'select',
            search: true,
            options: INDUSTRY_SECTOR(i18n),
        },
        durationOfService: {
            order: 13,
            type: 'yearsMonths',
        },
        durationWithCurrentEmployer: {
            order: 14,
            type: 'yearsMonths',
        },
        incomeType: {
            order: 15,
            type: 'select',
            search: true,
            options: INCOME_TYPE(i18n),
        },
        hasBankAccounts: {
            order: 16,
            type: 'toggle',
        },
        hasSavingsAccounts: {
            order: 17,
            type: 'toggle',
        },
        totalQualifyingIncome: {
            order: 18,
            type: 'money',
        },
        employerName: {
            order: 19,
            type: 'text',
        },
    },
    escalation: {
        insurer: {
            order: 1,
            type: 'select',
            search: false,
            options: INSURERS(i18n),
        },
        insuranceType: {
            order: 2,
            type: 'select',
            search: false,
            options: INSURANCE_TYPE(i18n),
        },
        selfEmployedNoIncomeValidation: {
            order: 3,
            type: 'toggle',
        },
        lenderRequestedReferral: {
            order: 4,
            type: 'toggle',
        },
        forceUnderwriterReview: {
            order: 5,
            type: 'toggle',
        },
        comment: {
            order: 6,
            type: 'text',
        },
    },
});

export const PER_PAGE = 12;

export const APP_TRACKER_STATE_VALUES = {
    CREATED: 'CREATED',
    SUBMITTED: 'SUBMITTED',
    UNDER_REVISION: 'UNDER_REVISION',
    REVIEWED: 'REVIEWED',
    NOTES_SUBMITTED: 'NOTES_SUBMITTED',
    LENDER_SUBMITTED: 'LENDER_SUBMITTED',
    LENDER_APPROVED: 'LENDER_APPROVED',
    PENDING_COMMITMENT_SIGNATURE: 'PENDING_COMMITMENT_SIGNATURE',
    PENDING_CONDITIONS: 'PENDING_CONDITIONS',
    COMPLETE: 'COMPLETE',
    NOTARY_ALERTED: 'NOTARY_ALERTED',
    FUNDED: 'FUNDED',
    CLOSED: 'CLOSED',
    EXPIRED: 'EXPIRED',
};

export const APPLICATION_STATUS_OPTIONS = (i18n: I18n) =>
    Object.values(APP_TRACKER_STATE_VALUES).map((state) => ({
        value: state,
        label: i18n._(`state.${state}`),
    }));

export const stateColumns = {
    inProgress: {
        completed: [
            APP_TRACKER_STATE_VALUES.SUBMITTED,
            APP_TRACKER_STATE_VALUES.UNDER_REVISION,
            APP_TRACKER_STATE_VALUES.REVIEWED,
            APP_TRACKER_STATE_VALUES.NOTES_SUBMITTED,
            APP_TRACKER_STATE_VALUES.LENDER_SUBMITTED,
            APP_TRACKER_STATE_VALUES.LENDER_APPROVED,
            APP_TRACKER_STATE_VALUES.PENDING_COMMITMENT_SIGNATURE,
            APP_TRACKER_STATE_VALUES.PENDING_CONDITIONS,
            APP_TRACKER_STATE_VALUES.COMPLETE,
            APP_TRACKER_STATE_VALUES.NOTARY_ALERTED,
            APP_TRACKER_STATE_VALUES.FUNDED,
            APP_TRACKER_STATE_VALUES.CLOSED,
            APP_TRACKER_STATE_VALUES.EXPIRED,
        ],
        ending: [APP_TRACKER_STATE_VALUES.CREATED],
    },
    submitted: {
        completed: [
            APP_TRACKER_STATE_VALUES.UNDER_REVISION,
            APP_TRACKER_STATE_VALUES.REVIEWED,
            APP_TRACKER_STATE_VALUES.NOTES_SUBMITTED,
            APP_TRACKER_STATE_VALUES.LENDER_SUBMITTED,
            APP_TRACKER_STATE_VALUES.LENDER_APPROVED,
            APP_TRACKER_STATE_VALUES.PENDING_COMMITMENT_SIGNATURE,
            APP_TRACKER_STATE_VALUES.PENDING_CONDITIONS,
            APP_TRACKER_STATE_VALUES.COMPLETE,
            APP_TRACKER_STATE_VALUES.NOTARY_ALERTED,
            APP_TRACKER_STATE_VALUES.FUNDED,
            APP_TRACKER_STATE_VALUES.CLOSED,
            APP_TRACKER_STATE_VALUES.EXPIRED,
        ],
        ending: [APP_TRACKER_STATE_VALUES.SUBMITTED],
    },
    underReview: {
        completed: [
            APP_TRACKER_STATE_VALUES.LENDER_SUBMITTED,
            APP_TRACKER_STATE_VALUES.LENDER_APPROVED,
            APP_TRACKER_STATE_VALUES.PENDING_COMMITMENT_SIGNATURE,
            APP_TRACKER_STATE_VALUES.PENDING_CONDITIONS,
            APP_TRACKER_STATE_VALUES.COMPLETE,
            APP_TRACKER_STATE_VALUES.NOTARY_ALERTED,
            APP_TRACKER_STATE_VALUES.FUNDED,
            APP_TRACKER_STATE_VALUES.CLOSED,
            APP_TRACKER_STATE_VALUES.EXPIRED,
        ],
        ending: [
            APP_TRACKER_STATE_VALUES.UNDER_REVISION,
            APP_TRACKER_STATE_VALUES.REVIEWED,
            APP_TRACKER_STATE_VALUES.NOTES_SUBMITTED,
        ],
    },
    submittedToLender: {
        completed: [
            APP_TRACKER_STATE_VALUES.PENDING_COMMITMENT_SIGNATURE,
            APP_TRACKER_STATE_VALUES.PENDING_CONDITIONS,
            APP_TRACKER_STATE_VALUES.COMPLETE,
            APP_TRACKER_STATE_VALUES.NOTARY_ALERTED,
            APP_TRACKER_STATE_VALUES.FUNDED,
            APP_TRACKER_STATE_VALUES.CLOSED,
            APP_TRACKER_STATE_VALUES.EXPIRED,
        ],
        ending: [
            APP_TRACKER_STATE_VALUES.LENDER_SUBMITTED,
            APP_TRACKER_STATE_VALUES.LENDER_APPROVED,
        ],
    },
    approvalLetter: {
        completed: [
            APP_TRACKER_STATE_VALUES.PENDING_CONDITIONS,
            APP_TRACKER_STATE_VALUES.COMPLETE,
            APP_TRACKER_STATE_VALUES.NOTARY_ALERTED,
            APP_TRACKER_STATE_VALUES.FUNDED,
            APP_TRACKER_STATE_VALUES.CLOSED,
            APP_TRACKER_STATE_VALUES.EXPIRED,
        ],
        ending: [APP_TRACKER_STATE_VALUES.PENDING_COMMITMENT_SIGNATURE],
    },
    documents: {
        completed: [
            APP_TRACKER_STATE_VALUES.COMPLETE,
            APP_TRACKER_STATE_VALUES.NOTARY_ALERTED,
            APP_TRACKER_STATE_VALUES.FUNDED,
            APP_TRACKER_STATE_VALUES.CLOSED,
            APP_TRACKER_STATE_VALUES.EXPIRED,
        ],
        ending: [APP_TRACKER_STATE_VALUES.PENDING_CONDITIONS],
    },
    completed: {
        completed: [],
        ending: [
            APP_TRACKER_STATE_VALUES.COMPLETE,
            APP_TRACKER_STATE_VALUES.NOTARY_ALERTED,
            APP_TRACKER_STATE_VALUES.FUNDED,
            APP_TRACKER_STATE_VALUES.CLOSED,
            APP_TRACKER_STATE_VALUES.EXPIRED,
        ],
    },
};

const createLenderValues = (value: number | string, label: string) => ({
    value,
    label,
});

// TODO add missing lenders
// Seem to be missing lenders here
export const LENDER_INFO_VALUES = [
    createLenderValues(3, 'CMLS'),
    createLenderValues(4, 'First National Financial'),
    createLenderValues(7, 'Lendwise'),
    createLenderValues(8, 'MCAP'),
    createLenderValues(9, 'Merix'),
    createLenderValues(12, 'Scotia Bank'),
    createLenderValues(13, 'Street Capital'),
    createLenderValues(14, 'TD'),
    createLenderValues(16, 'Equitable Bank'),
    createLenderValues(26, 'Desjardins'),
    createLenderValues(27, 'DUCA'),
    createLenderValues(53, 'Alterna'),
    createLenderValues(54, 'Wealth One Bank'),
    createLenderValues(60, 'xmc'),
    createLenderValues(1000, 'UNI'),
    createLenderValues(6, 'ICICI Bank'),
    createLenderValues('*', 'nesto'),
];

export const PROPERTY_TYPE_OPTIONS = (i18n): SelectOptions<PropertyType> => [
    { value: 'DETACHED', label: i18n._(`propertyType.detached`) },
    { value: 'SEMI_DETACHED', label: i18n._(`propertyType.semidetached`) },
    {
        value: 'DUPLEX_DETACHED',
        label: i18n._(`propertyType.duplex_detached`),
    },
    {
        value: 'DUPLEX_SEMI_DETACHED',
        label: i18n._(`propertyType.duplex_semi_detached`),
    },
    { value: 'ROW_HOUSING', label: i18n._(`propertyType.row_housing`) },
    {
        value: 'APARTMENT_LOW_RISE',
        label: i18n._(`propertyType.apartment_low_rise`),
    },
    {
        value: 'APARTMENT_HIGH_RISE',
        label: i18n._(`propertyType.apartment_hi_rise`),
    },
    { value: 'MOBILE', label: i18n._(`propertyType.mobile`) },
    {
        value: 'TRI_PLEX_DETACHED',
        label: i18n._(`propertyType.tri_plex_detached`),
    },
    {
        value: 'TRI_PLEX_SEMI_DETACHED',
        label: i18n._(`propertyType.tri_plex_semi_detached`),
    },
    { value: 'STACKED', label: i18n._(`propertyType.stacked`) },
    {
        value: 'MODULAR_HOME_DETACHED',
        label: i18n._(`propertyType.modular_home_detached`),
    },
    {
        value: 'MODULAR_HOME_SEMI_DETACHED',
        label: i18n._(`propertyType.modular_home_semi_detached`),
    },
    {
        value: 'FOUR_PLEX_DETACHED',
        label: i18n._(`propertyType.four_plex_detached`),
    },
    {
        value: 'FOUR_PLEX_SEMI_DETACHED',
        label: i18n._(`propertyType.four_plex_semi_detached`),
    },
    {
        value: 'SeasonalHome',
        label: i18n._(`propertyType.seasonal_home`),
    },
    { value: 'OTHER', label: i18n._(`propertyType.other`) },
];

export const PROPERTY_TYPE_UNIT_COUNT_MAPPING: Record<string, number> = {
    DUPLEX_DETACHED: 2,
    DUPLEX_SEMI_DETACHED: 2,
    TRI_PLEX_DETACHED: 3,
    TRI_PLEX_SEMI_DETACHED: 3,
    FOUR_PLEX_DETACHED: 4,
    FOUR_PLEX_SEMI_DETACHED: 4,
};

export const OTHER_PROPERTY_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<OtherPropertyType> => [
    { label: i18n._(`propertyType.house`), value: 'HOUSE' },
    { label: i18n._(`propertyType.condo`), value: 'CONDO' },
    {
        label: i18n._(`propertyType.secondaryHome`),
        value: 'SECONDARY_HOME_OR_COTTAGE',
    },
    { label: i18n._(`propertyType.duplex`), value: 'DUPLEX' },
    { label: i18n._(`propertyType.triplex`), value: 'TRIPLEX' },
    { label: i18n._(`propertyType.fourplex`), value: 'FOURPLEX' },
];

export const PREQUAL_PROPERTY_TYPE_OPTIONS = (
    i18n
): SelectOptions<PrequalPropertyType> => [
    { label: i18n._(`propertyType.house`), value: 'HOUSE' },
    { label: i18n._(`propertyType.homeOrCondo`), value: 'HOME_OR_CONDO' },
    { label: i18n._(`propertyType.condo`), value: 'CONDO' },
    { label: i18n._(`propertyType.secondHome`), value: 'SECOND_HOME' },
    {
        label: i18n._(`propertyType.secondHomeOrCottage`),
        value: 'SECOND_HOME_OR_COTTAGE',
    },
    {
        label: i18n._(`propertyType.secondaryHome`),
        value: 'SECONDARY_HOME_OR_COTTAGE',
    },
    { label: i18n._(`propertyType.duplex`), value: 'DUPLEX' },
    { label: i18n._(`propertyType.triplex`), value: 'TRIPLEX' },
    { label: i18n._(`propertyType.fourplex`), value: 'FOURPLEX' },
    { label: i18n._(`propertyType.rental`), value: 'RENTAL' },
    { label: i18n._(`propertyType.fiveUnits`), value: 'FIVE_UNITS_PLUS' },
    { label: i18n._(`propertyType.other`), value: 'OTHER' },
    { label: i18n._(`propertyType.cottage`), value: 'COTTAGE' },
];

export const PROPERTY_PURPOSE = (i18n: I18n) => [
    {
        value: 'OWNER_OCCUPIED',
        label: i18n._(`propertyPurpose.ownerOccupied`),
    },
    {
        value: 'ANY_RENTAL',
        label: i18n._(`propertyPurpose.anyRental`),
    },
    {
        value: 'RENTAL_1_UNIT',
        label: i18n._(`propertyPurpose.rental1Unit`),
    },
    {
        value: 'RENTAL_2-4_UNITS',
        label: i18n._(`propertyPurpose.rental2-4Units`),
    },
];

export const OTHER_PROPERTY_PURPOSE = (i18n: I18n) => [
    {
        value: 'OWNER_OCCUPIED',
        label: i18n._(`afterTransaction.ownerOccupied`),
    },
    {
        value: 'OWNER_OCCUPIED_AND_RENTAL',
        label: i18n._(`afterTransaction.ownerOccupiedAndRental`),
    },
    {
        value: 'RENTAL',
        label: i18n._(`afterTransaction.rental`),
    },
];

export const AFTER_TRANSACTION_PURPOSE_OPTIONS = (i18n: I18n) => [
    {
        value: 'SOLD',
        label: i18n._(`afterTransaction.sold`),
    },
    {
        value: 'OWNER_OCCUPIED',
        label: i18n._(`afterTransaction.ownerOccupied`),
    },
    {
        value: 'OWNER_OCCUPIED_AND_RENTAL',
        label: i18n._(`afterTransaction.ownerOccupiedAndRental`),
    },
    {
        value: 'RENTAL',
        label: i18n._(`afterTransaction.rental`),
    },
];

export const AFTER_TRANSACTION_PURPOSE_LABEL = (
    i18n: I18n
): Record<PurposeAfterTransaction, string> => ({
    SOLD: i18n._('afterTransaction.sold'),
    OWNER_OCCUPIED: i18n._('afterTransaction.ownerOccupied'),
    OWNER_OCCUPIED_AND_RENTAL: i18n._(
        'afterTransaction.ownerOccupiedAndRental'
    ),
    RENTAL: i18n._('afterTransaction.rental'),
});

export const CURRENT_SALE_STATUS = (i18n: I18n) => [
    {
        value: 'NOT_READY_YET',
        label: i18n._(`currentSaleStatus.notReadyYet`),
    },
    {
        value: 'LISTED_FOR_SALE',
        label: i18n._(`currentSaleStatus.listedForSale`),
    },
    {
        value: 'CONDITIONALLY_SOLD',
        label: i18n._(`currentSaleStatus.conditionallySold`),
    },
    {
        value: 'SOLD_FIRM',
        label: i18n._(`currentSaleStatus.soldFirm`),
    },
];

export const RATE_TYPES = (i18n: I18n) => [
    {
        value: 'FIXED',
        label: i18n._(`Fixed`),
    },
    {
        value: 'VARIABLE',
        label: i18n._(`Variable`),
    },
];

export const INSURANCE = (i18n: I18n) => [
    {
        value: 'INSURED',
        label: i18n._(`insured`),
    },
    {
        value: 'CONVENTIONAL',
        label: i18n._(`conventional`),
    },
];

export const LENDER_TYPE = (i18n: I18n) => [
    {
        value: 'BANK',
        label: i18n._(`bank`),
    },
    {
        value: 'MONOLINE',
        label: i18n._(`monoline`),
    },
    {
        value: 'CREDIT_UNION',
        label: i18n._(`creditUnion`),
    },
    {
        value: 'ALTERNATE',
        label: i18n._(`alternate`),
    },
];
export enum terms {
    ONEYEAR = '1_YEAR',
    TWOYEAR = '2_YEAR',
    THREEYEAR = '3_YEAR',
    FOURYEAR = '4_YEAR',
    FIVEYEAR = '5_YEAR',
    SIXYEAR = '6_YEAR',
    SEVENYEAR = '7_YEAR',
    TENYEAR = '10_YEAR',
}

export enum lightQualificationSection {
    IDENTIFICATION = 'IDENTIFICATION',
    PROPERTY = 'PROPERTY',
    DOWN_PAYMENTS = 'DOWN_PAYMENTS',
    OWNED_PROPERTIES = 'OWNED_PROPERTIES',
    EMPLOYMENTS = 'EMPLOYMENTS',
}

interface TermSelectOption {
    value: AllowedTerm;
    label: string;
}

export const TERM: (i18n: I18n) => TermSelectOption[] = (i18n) => [
    {
        value: '1_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 1 } }),
    },
    {
        value: '2_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 2 } }),
    },
    {
        value: '3_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 3 } }),
    },
    {
        value: '4_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 4 } }),
    },
    {
        value: '5_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 5 } }),
    },
    {
        value: '6_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 6 } }),
    },
    {
        value: '7_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 7 } }),
    },
    {
        value: '10_YEAR',
        label: i18n._({ id: 'termLength', values: { termLength: 10 } }),
    },
];

export const RATE_HOLD = (i18n: I18n) => [
    {
        value: '30_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 30 } }),
    },
    {
        value: '45_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 45 } }),
    },
    {
        value: '60_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 60 } }),
    },
    {
        value: '90_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 90 } }),
    },
    {
        value: '110_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 110 } }),
    },
    {
        value: '120_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 120 } }),
    },
    {
        value: '150_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 150 } }),
    },
    {
        value: '180_DAYS',
        label: i18n._({ id: 'rateHold.value', values: { rateHold: 180 } }),
    },
];

/**
 * This function returns the amortization options in years based on the maximum amortization
 * @param maxAmortization Maximum amortization
 * @returns The amortization options in years based on the maximum amortization
 */
export const AMORTIZATION_OPTIONS_YEARS = (maxAmortization: number) =>
    Array.from({ length: maxAmortization }, (_, i) => ({
        value: i + 1,
        label: i + 1,
    }));

export const AMORTIZATION_OPTIONS_MONTHS = Array.from(
    { length: 13 },
    (_, i) => ({
        value: i,
        label: i,
    })
);

export const SALUTATION = [
    'MR',
    'MRS',
    'MS',
    'MISS',
    'DR',
    'REV',
    'JUDGE',
    'PROF',
] as const;
// TODO translation of label
export const SALUTATION_OPTIONS = (i18n): SelectOptions<Salutation> => [
    {
        value: 'MR',
        label: i18n._(`salutations.mr`),
    },
    {
        value: 'MRS',
        label: i18n._(`salutations.mrs`),
    },
    {
        value: 'MS',
        label: i18n._(`salutations.ms`),
    },
    {
        value: 'MISS',
        label: i18n._(`salutations.miss`),
    },
    {
        value: 'DR',
        label: i18n._(`salutations.dr`),
    },
    {
        value: 'REV',
        label: i18n._(`salutations.rev`),
    },
    {
        value: 'JUDGE',
        label: i18n._(`salutations.judge`),
    },
];

export const YES_NO = ['YES', 'NO'] as const;
export const YES_NO_OPTIONS = (
    i18n?: I18n
): SelectOptions<'true' | 'false'> => [
    {
        value: 'true',
        label: i18n._(`yes`),
    },
    {
        value: 'false',
        label: i18n._(`no`),
    },
];
export const YES_NO_STRING_OPTIONS = (
    i18n?: I18n
): SelectOptions<YesNoOptional> => [
    {
        value: 'YES',
        label: i18n._(`yes`),
    },
    {
        value: 'NO',
        label: i18n._(`no`),
    },
];

export const ADDRESS_SITUATION = [
    'RENTING',
    'OWNER',
    'LIVING_WITH_PARENTS',
] as const;
export const ADDRESS_SITUATION_OPTIONS = (
    i18n?: I18n
): SelectOptions<AddressSituation> => [
    {
        value: 'RENTING',
        label: i18n._(`renting`),
    },
    {
        value: 'OWNER',
        label: i18n._(`owner`),
    },
    {
        value: 'LIVING_WITH_PARENTS',
        label: i18n._(`livingWithParents`),
    },
];

export const RENTING_FREQUENCY = ['WEEKLY', 'MONTHLY', 'ANNUALLY'] as const;
export const RENTING_FREQUENCY_OPTIONS = (
    i18n?: I18n
): SelectOptions<RentingFrequency> => [
    {
        value: 'WEEKLY',
        label: i18n._(`Weekly`),
    },
    {
        value: 'MONTHLY',
        label: i18n._(`Monthly`),
    },
    {
        value: 'ANNUALLY',
        label: i18n._(`Annually`),
    },
];

export const FREQUENCY = (i18n: I18n): SelectOptions<Frequency> => [
    {
        value: 'ANNUALLY',
        label: i18n._(`frequency.yr`),
    },
    {
        value: 'SEMIANNUALLY',
        label: i18n._(`frequency.sa`),
    },
    {
        value: 'MONTHLY',
        label: i18n._(`frequency.mo`),
    },
    {
        value: 'SEMIMONTHLY',
        label: i18n._(`frequency.sm`),
    },
    {
        value: 'BIWEEKLY',
        label: i18n._(`frequency.bw`),
    },
    {
        value: 'WEEKLY',
        label: i18n._(`frequency.wkly`),
    },
];

export const MORTGAGE_TERM_PAYMENT_FREQUENCY_OPTIONS = (i18n: I18n) =>
    FREQUENCY(i18n).filter(({ value }) => value !== 'SEMIANNUALLY');

export const RECURRING_PAYMENT_FREQUENCY_OPTIONS = (
    i18n: I18n
): SelectOptions<string> => [
    ...FREQUENCY(i18n),
    { value: 'ACCELERATED_BIWEEKLY', label: i18n._('frequency.bwAcc') },
    { value: 'ACCELERATED_WEEKLY', label: i18n._('frequency.wklyAcc') },
    { value: 'DAILY', label: i18n._('frequency.daily') },
];

export const getProductFrequencyOptions = (
    i18n: I18n,
    product?: Product
): SelectOptions<string> => {
    if (!product) {
        return [];
    }

    const options: SelectOptions<string> = [];

    if (product.paymentOptionWeekly) {
        options.push({
            value: 'WEEKLY',
            label: i18n._(`frequency.wkly`),
        });
    }

    if (product.paymentOptionWeeklyAccelerated) {
        options.push({
            value: 'ACCELERATED_WEEKLY',
            label: i18n._('frequency.wklyAcc'),
        });
    }

    if (product.paymentOptionBiWeekly) {
        options.push({
            value: 'BIWEEKLY',
            label: i18n._(`frequency.bw`),
        });
    }

    if (product.paymentOptionBiWeeklyAccelerated) {
        options.push({
            value: 'ACCELERATED_BIWEEKLY',
            label: i18n._('frequency.bwAcc'),
        });
    }

    if (product.paymentOptionSemiMonthly) {
        options.push({
            value: 'SEMIMONTHLY',
            label: i18n._(`frequency.sm`),
        });
    }

    if (product.paymentOptionMonthly) {
        options.push({
            value: 'MONTHLY',
            label: i18n._(`frequency.mo`),
        });
    }

    return options;
};

export const SEMI_MONTHLY_FREQUENCY_OPTIONS = (i18n: I18n) => [
    {
        value: SemiMonthlyFrequency.NONE,
        label: i18n._(`None`),
    },
    {
        value: SemiMonthlyFrequency.FIRST_AND_FIFTEENTH,
        label: i18n._(`semiMonthly.frequency.firstAndFifteenth`),
    },
    {
        value: SemiMonthlyFrequency.SECOND_AND_SIXTEENTH,
        label: i18n._(`semiMonthly.frequency.secondAndSixteenth`),
    },
];

export const MORTGAGE_TYPE = (i18n: I18n) => [
    {
        value: 'STANDARD',
        label: i18n._(`mortgageType.standard`),
    },
    {
        value: 'HELOC',
        label: i18n._(`mortgageType.heloc`),
    },
];

export const TERM_TYPE = (i18n: I18n) => [
    {
        value: 'OPEN',
        label: i18n._(`termType.open`),
    },
    {
        value: 'CLOSED',
        label: i18n._(`termType.closed`),
    },
    {
        value: 'CONVERTABLE',
        label: i18n._(`termType.convertable`),
    },
];
export const EMPLOYMENT_TYPE = (i18n: I18n) => [
    {
        value: 'FULL_TIME',
        label: i18n._(`fullTime`),
    },
    {
        value: 'PART_TIME',
        label: i18n._(`partTime`),
    },
    {
        value: 'SEASONAL',
        label: i18n._(`seasonal`),
    },
];

export const ASSET_INSTITUTION = [
    'BMO',
    'CIBC',
    'DESJARDINS',
    'ETRADE',
    'HSBC',
    'IG',
    'ITRADE',
    'LAURENTIAN',
    'NBC',
    'QTRADE_INVESTOR',
    'QUESTTRADE',
    'RBC',
    'SCOTIA_BANK',
    'SUNLIFE',
    'TANGERINE',
    'TD',
    'WEALTH_SIMPLE',
    'OTHER',
] as const;

export const INSTITUTION = (i18n: I18n): Record<AssetInstitution, string> => ({
    RBC: i18n._('rbc'),
    TD: i18n._('td'),
    SCOTIA_BANK: i18n._('scotia_bank'),
    BMO: i18n._('bmo'),
    CIBC: i18n._('cibc'),
    NBC: i18n._('nbc'),
    DESJARDINS: i18n._('desjardins'),
    SUNLIFE: i18n._('sunlife'),
    HSBC: i18n._('hsbc'),
    TANGERINE: i18n._('tangerine'),
    IG: i18n._('ig'),
    QUESTTRADE: i18n._('questtrade'),
    WEALTH_SIMPLE: i18n._('wealth_simple'),
    ETRADE: i18n._('etrade'),
    ITRADE: i18n._('itrade'),
    QTRADE_INVESTOR: i18n._('qtrade_investor'),
    LAURENTIAN: i18n._('laurentian'),
    OTHER: i18n._('other'),
});

export const FAMILY_RELATIONSHIP = [
    'FATHER',
    'MOTHER',
    'CHILD',
    'BROTHER',
    'SISTER',
    'GRAND_FATHER',
    'GRAND_MOTHER',
    'UNCLE',
    'AUNT',
] as const;

export const FAMILY_RELATIONSHIP_OPTIONS = (
    i18n?: I18n
): SelectOptions<FamilyRelationship> => [
    {
        value: 'FATHER',
        label: i18n._('familyRelationship.FATHER'),
    },
    {
        value: 'MOTHER',
        label: i18n._('familyRelationship.MOTHER'),
    },
    {
        value: 'CHILD',
        label: i18n._('familyRelationship.CHILD'),
    },
    {
        value: 'BROTHER',
        label: i18n._('familyRelationship.BROTHER'),
    },
    {
        value: 'SISTER',
        label: i18n._('familyRelationship.SISTER'),
    },
    {
        value: 'GRAND_FATHER',
        label: i18n._('familyRelationship.GRAND_FATHER'),
    },
    {
        value: 'GRAND_MOTHER',
        label: i18n._('familyRelationship.GRAND_MOTHER'),
    },
    {
        value: 'UNCLE',
        label: i18n._('familyRelationship.UNCLE'),
    },
    {
        value: 'AUNT',
        label: i18n._('familyRelationship.AUNT'),
    },
];

export const LENDER = (i18n: I18n): Record<Lender, string> => ({
    DESJARDINS: i18n._(`lender.desjardins`),
    SCOTIABANK: i18n._(`lender.scotia`),
    LAURENTIAN: i18n._(`lender.laurentian`),
    TD: i18n._(`lender.td`),
    RBC: i18n._(`lender.rbc`),
    CIBC: i18n._(`lender.cibc`),
    NATIONAL_BANK: i18n._(`lender.nationalBank`),
    BMO: i18n._(`lender.bmo`),
    MCAP: i18n._(`lender.mcap_display`),
    TANGERINE: i18n._(`lender.tangerine`),
    B2B_LAURENTIAN: i18n._(`lender.b2b_laurentian`),
    FIRST_NATIONAL: i18n._(`lender.first_national`),
    EQUITABLE_BANK: i18n._(`lender.equitable_bank`),
    STREET_CAPITAL: i18n._(`lender.street_capital`),
    LENDWISE: i18n._(`lender.lendwise`),
    MERIX: i18n._(`lender.merix`),
    CMLS: i18n._(`lender.cmls`),
    ALTERNA: i18n._(`lender.alterna`),
    RADIUS_FINANCIAL: i18n._(`lender.radiusFinancial`),
    DUCA: i18n._(`lender.duca`),
    HOME_TRUST: i18n._(`lender.homeTrust`),
    ICICI_BANK: i18n._(`lender.iciciBank`),
    IG: i18n._(`lender.ig`),
    INDUSTRIAL_ALLIANCE: i18n._(`lender.industrialAlliance`),
    MANULIFE: i18n._(`lender.manulife`),
    MEREDIAN: i18n._(`lender.meredian`),
    OPTIMUM_MORTGAGE: i18n._(`lender.optimumMortgage`),
    PC_FINANCIALS: i18n._(`lender.pcFinancials`),
    UNI: i18n._(`lender.uni`),
    PRIVATE: i18n._(`lender.private`),
    SOLUTIONS_BANKING: i18n._(`lender.solutionsBanking`),
    OTHER: i18n._(`other`),
});

export const LENDER_VALUES = {
    3: 'CMLS',
    4: 'First National Financial',
    7: 'Lendwise',
    8: 'MCAP',
    9: 'Merix',
    12: 'Scotia Bank',
    13: 'Street Capital',
    14: 'TD',
    16: 'Equitable Bank',
    26: 'Desjardins',
    27: 'DUCA',
    53: 'Alterna',
    54: 'Wealth One Bank',
    60: 'xmc',
    1000: 'UNI',
    6: 'ICICI Bank',
    '*': 'nesto',
};

export const LENDER_SELECT_OPTIONS = (i18n): SelectOptions<Lender> => [
    // Because of sorting TS throw error
    // Type 'string' is not assignable to type 'Lender'.
    // @ts-ignore
    ...[
        {
            value: 'ALTERNA',
            label: LENDER(i18n).ALTERNA,
        },
        {
            value: 'B2B_LAURENTIAN',
            label: LENDER(i18n).B2B_LAURENTIAN,
        },
        {
            value: 'BMO',
            label: LENDER(i18n).BMO,
        },
        {
            value: 'CIBC',
            label: LENDER(i18n).CIBC,
        },
        {
            value: 'CMLS',
            label: LENDER(i18n).CMLS,
        },
        {
            value: 'DESJARDINS',
            label: LENDER(i18n).DESJARDINS,
        },
        {
            value: 'DUCA',
            label: LENDER(i18n).DUCA,
        },
        {
            value: 'EQUITABLE_BANK',
            label: LENDER(i18n).EQUITABLE_BANK,
        },
        {
            value: 'FIRST_NATIONAL',
            label: LENDER(i18n).FIRST_NATIONAL,
        },
        {
            value: 'LAURENTIAN',
            label: LENDER(i18n).LAURENTIAN,
        },
        {
            value: 'HOME_TRUST',
            label: LENDER(i18n).HOME_TRUST,
        },
        {
            value: 'ICICI_BANK',
            label: LENDER(i18n).ICICI_BANK,
        },
        {
            value: 'INDUSTRIAL_ALLIANCE',
            label: LENDER(i18n).INDUSTRIAL_ALLIANCE,
        },
        {
            value: 'LENDWISE',
            label: LENDER(i18n).LENDWISE,
        },
        {
            value: 'MANULIFE',
            label: LENDER(i18n).MANULIFE,
        },
        {
            value: 'MCAP',
            label: LENDER(i18n).MCAP,
        },
        {
            value: 'MEREDIAN',
            label: LENDER(i18n).MEREDIAN,
        },
        {
            value: 'MERIX',
            label: LENDER(i18n).MERIX,
        },
        {
            value: 'NATIONAL_BANK',
            label: LENDER(i18n).NATIONAL_BANK,
        },
        {
            value: 'OPTIMUM_MORTGAGE',
            label: LENDER(i18n).OPTIMUM_MORTGAGE,
        },
        {
            value: 'PC_FINANCIALS',
            label: LENDER(i18n).PC_FINANCIALS,
        },
        {
            value: 'RADIUS_FINANCIAL',
            label: LENDER(i18n).RADIUS_FINANCIAL,
        },
        {
            value: 'RBC',
            label: LENDER(i18n).RBC,
        },
        {
            value: 'SCOTIABANK',
            label: LENDER(i18n).SCOTIABANK,
        },
        {
            value: 'STREET_CAPITAL',
            label: LENDER(i18n).STREET_CAPITAL,
        },
        {
            value: 'TANGERINE',
            label: LENDER(i18n).TANGERINE,
        },

        {
            value: 'TD',
            label: LENDER(i18n).TD,
        },
        {
            value: 'IG',
            label: LENDER(i18n).IG,
        },
        {
            value: 'SOLUTIONS_BANKING',
            label: LENDER(i18n).SOLUTIONS_BANKING,
        },
    ].sort((a, b) => (a.label > b.label ? 1 : -1)),
    // Keep this last 2 at the end after sorted values
    {
        // @ts-ignore
        value: 'PRIVATE',
        label: LENDER(i18n).PRIVATE,
    },
    {
        // @ts-ignore
        value: 'OTHER',
        label: LENDER(i18n).OTHER,
    },
];

export const PROPERTY_PURPOSE_OPTIONS = (
    i18n: I18n
): SelectOptions<Purpose> => [
    {
        value: 'OWNER_OCCUPIED',
        label: i18n._('purpose.ownerOccupied'),
    },
    {
        value: 'OWNER_OCCUPIED_AND_RENTAL',
        label: i18n._('purpose.ownerOccupiedAndRental'),
    },
    {
        value: 'RENTAL',
        label: i18n._('purpose.rental'),
    },
    {
        value: 'SECONDARY_RESIDENCE',
        label: i18n._('purpose.secondaryResidence'),
    },
];

export const PROPERTY_PURPOSE_LABEL = (i18n: I18n) => ({
    OWNER_OCCUPIED: i18n._('purpose.ownerOccupied'),
    OWNER_OCCUPIED_AND_RENTAL: i18n._('purpose.ownerOccupiedAndRental'),
    RENTAL: i18n._('purpose.rental'),
    SOLD: i18n._('afterTransaction.sold'),
});

export const PAYMENT_FREQUENCY_OPTIONS = (
    i18n: I18n
): SelectOptions<Frequency> => [
    {
        value: 'ANNUALLY',
        label: i18n._('annually'),
    },
    {
        value: 'SEMIANNUALLY',
        label: i18n._('semiannually'),
    },
    {
        value: 'MONTHLY',
        label: i18n._('monthly'),
    },
    {
        value: 'SEMIMONTHLY',
        label: i18n._('semimonthly'),
    },
    {
        value: 'BIWEEKLY',
        label: i18n._('biweekly'),
    },
    {
        value: 'WEEKLY',
        label: i18n._('weekly'),
    },
];

export const MORTGAGE_PAYMENT_FREQUENCY_OPTIONS = (
    i18n: I18n
): SelectOptions<MortgagePaymentFrequency> => [
    {
        value: 'MONTHLY',
        label: i18n._('monthly'),
    },
    {
        value: 'SEMIMONTHLY',
        label: i18n._('semimonthly'),
    },
    {
        value: 'BIWEEKLY',
        label: i18n._('biweekly'),
    },
    {
        value: 'ACCELERATED_BIWEEKLY',
        label: i18n._('biweeklyAcc'),
    },
    {
        value: 'WEEKLY',
        label: i18n._('weekly'),
    },
    {
        value: 'ACCELERATED_WEEKLY',
        label: i18n._('weeklyAcc'),
    },
];

export const OWNED_PROPERTY_PAYMENT_FREQUENCY_OPTIONS = (
    i18n: I18n
): SelectOptions<MortgagePaymentFrequency> => [
    {
        value: 'MONTHLY',
        label: i18n._('monthly'),
    },
    {
        value: 'SEMIMONTHLY',
        label: i18n._('semimonthly'),
    },
    {
        value: 'BIWEEKLY',
        label: i18n._('biweekly'),
    },
    {
        value: 'WEEKLY',
        label: i18n._('weekly'),
    },
];

export const CONSTRUCTION_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<ConstructionType> => [
    {
        value: 'NEW_BUILDING',
        label: i18n._(`constructionType.newBuilding`),
    },
    {
        value: 'EXISTING_BUILDING',
        label: i18n._(`constructionType.existingBuilding`),
    },
];

//
export const FUND_PURPOSE_OPTIONS = (
    i18n: I18n
): SelectOptions<FundPurpose> => [
    {
        value: 'HOME_IMPROVEMENT',
        label: i18n._(`fundPurpose.homeImprovement`),
    },
    {
        value: 'DEBT_CONSOLIDATION',
        label: i18n._(`fundPurpose.debpConsolidation`),
    },
    {
        value: 'INVESTMENT',
        label: i18n._(`fundPurpose.investment`),
    },
    {
        value: 'GIFT_TO_RELATIVE',
        label: i18n._(`fundPurpose.giftToRelative`),
    },
];

//
export const HEATING_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<HeatingType> => [
    {
        value: 'ELECTRIC',
        label: i18n._(`heatingType.electric`),
    },
    {
        value: 'FORCED_AIR_OIL_GAS_ELECTRIC',
        label: i18n._(`heatingType.forcedAirOilGasElectric`),
    },
    {
        value: 'HOT_WATER',
        label: i18n._(`heatingType.hotWater`),
    },
    {
        value: 'OTHER',
        label: i18n._(`other`),
    },
];

//
export const PROPERTY_STYLE_OPTIONS = (
    i18n: I18n
): SelectOptions<PropertyStyle> => [
    {
        value: 'ONE_STOREY',
        label: i18n._(`propertyStyle.oneStorey`),
    },
    {
        value: 'BI_LEVEL',
        label: i18n._(`propertyStyle.biLevel`),
    },
    {
        value: 'TWO_STORY',
        label: i18n._(`propertyStyle.twoStorey`),
    },
    {
        value: 'SPLIT_LEVEL',
        label: i18n._(`propertyStyle.splitLevel`),
    },
    {
        value: 'STOREY_AND_A_HALF',
        label: i18n._(`propertyStyle.storeyAndAHalf`),
    },
    {
        value: 'THREE_STOREY',
        label: i18n._(`propertyStyle.threeStorey`),
    },
    {
        value: 'OTHER',
        label: i18n._(`propertyStyle.other`),
    },
];

//
export const SEWAGE_TYPE_OPTIONS = (i18n: I18n): SelectOptions<SewageType> => [
    {
        value: 'MUNICIPAL',
        label: i18n._(`municipal`),
    },
    {
        value: 'SEPTIC',
        label: i18n._(`sewageType.septic`),
    },
    {
        value: 'HOLDING_TANK',
        label: i18n._(`sewageType.holdingTank`),
    },
];

//
export const PROPERTY_TENURE_OPTIONS = (
    i18n: I18n
): SelectOptions<PropertyTenure> => [
    {
        value: 'FREE_HOLD',
        label: i18n._(`propertyTenure.freeHold`),
    },
    {
        value: 'CONDO',
        label: i18n._(`propertyTenure.condo`),
    },
    {
        value: 'LEASE_HOLD',
        label: i18n._(`propertyTenure.leaseHold`),
    },
];

//
export const WATER_TYPE_OPTIONS = (i18n: I18n): SelectOptions<WaterType> => [
    {
        value: 'MUNICIPAL',
        label: i18n._(`municipal`),
    },
    {
        value: 'WELL',
        label: i18n._(`waterType.well`),
    },
];

export const GARAGE_TYPE_OPTIONS = (i18n: I18n): SelectOptions<GarageType> => [
    { value: 'DETACHED', label: i18n._('garageType.detached') },
    { value: 'ATTACHED', label: i18n._('garageType.attached') },
];

export const GARAGE_SIZE_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<GarageSizeType> => [
    { value: 'SINGLE', label: i18n._('garagespace.single') },
    { value: 'DOUBLE', label: i18n._('garagespace.double') },
    { value: 'TRIPLE', label: i18n._('garagespace.triple') },
];

// UNIT_TYPE_OPTIONS
export enum OtherIncomeEnum {
    INVESTMENT_INCOME = 'INVESTMENT_INCOME',
    INTEREST_INCOME = 'INTEREST_INCOME',
    ALIMONY = 'ALIMONY',
    CHILD_SUPPORT = 'CHILD_SUPPORT',
    EMPLOYMENT_EXPENSE = 'EMPLOYMENT_EXPENSE',
    DISABILITY_INCOME = 'DISABILITY_INCOME',
    OTHER = 'OTHER',
}

export const OTHER_INCOME_OPTIONS = (
    i18n: I18n
): SelectOptions<OtherIncomeType> => [
    {
        value: OtherIncomeEnum.INVESTMENT_INCOME,
        label: i18n._('incomeType.investment'),
    },
    {
        value: OtherIncomeEnum.INTEREST_INCOME,
        label: i18n._('incomeType.interest'),
    },
    {
        value: OtherIncomeEnum.CHILD_SUPPORT,
        label: i18n._('incomeType.childSupport'),
    },
    {
        value: OtherIncomeEnum.ALIMONY,
        label: i18n._('incomeType.alimony'),
    },
    {
        value: OtherIncomeEnum.EMPLOYMENT_EXPENSE,
        label: i18n._('incomeType.employment'),
    },
    {
        value: OtherIncomeEnum.DISABILITY_INCOME,
        label: i18n._('incomeType.disability'),
    },
    {
        value: OtherIncomeEnum.OTHER,
        label: i18n._('other'),
    },
];

export const SURFACE_UNIT_OPTIONS = (i18n: I18n): SelectOptions<Surface> => [
    {
        value: 'SQ_FEET',
        label: i18n._('surface.squarefeet'),
    },
    {
        value: 'SQ_METERS',
        label: i18n._('surface.squaremeters'),
    },
];

export const INTEREST_RATE_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<InterestRateType> => [
    {
        value: 'VARIABLE',
        label: i18n._('interestRateType.variable'),
    },
    {
        value: 'FIXED',
        label: i18n._('interestRateType.fixed'),
    },
    {
        value: 'ADJUSTABLE',
        label: i18n._('interestRateType.adjustable'),
    },
    {
        value: 'CAPPED_VARIABLE',
        label: i18n._('interestRateType.cappedVariable'),
    },
];

export const MORTGAGE_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<MortgageType> => [
    {
        value: 'STANDARD',
        label: i18n._('mortgageType.standard'),
    },
    {
        value: 'HELOC',
        label: i18n._('mortgageType.heloc'),
    },
];

export const TERM_TYPE_OPTIONS = (i18n: I18n): SelectOptions<TermType> => [
    {
        value: 'OPEN',
        label: i18n._('termType.open'),
    },
    {
        value: 'CLOSED',
        label: i18n._('termType.closed'),
    },
    {
        value: 'CONVERTABLE',
        label: i18n._('termType.convertable'),
    },
];

export const ASSET_TYPE_OPTIONS = (i18n: I18n): SelectOptions<AssetTypes> => [
    {
        value: 'SAVINGS',
        label: i18n._('asset.savings'),
    },
    {
        value: 'TFSA',
        label: i18n._('asset.tfsa'),
    },
    {
        value: 'RRSP',
        label: i18n._('asset.rrsp'),
    },
    {
        value: 'NON_REGISTERED_INVESTMENTS',
        label: i18n._('asset.nonRegistered'),
    },
    {
        value: 'GIFT',
        label: i18n._('asset.gift'),
    },
    {
        value: 'PROPERTY',
        label: i18n._('asset.property'),
    },
    {
        value: 'VEHICLE',
        label: i18n._('asset.vehicle'),
    },
    {
        value: 'OTHER',
        label: i18n._('other'),
    },
    {
        value: 'HELOC',
        label: i18n._('heloc'),
    },
];

export const OTHER_INCOME_LABELS = (i18n: I18n) => ({
    [OtherIncomeEnum.INVESTMENT_INCOME]: i18n._(`incomeType.investment`),
    [OtherIncomeEnum.INTEREST_INCOME]: i18n._(`incomeType.interest`),
    [OtherIncomeEnum.CHILD_SUPPORT]: i18n._(`incomeType.childSupport`),
    [OtherIncomeEnum.DISABILITY_INCOME]: i18n._(`incomeType.disability`),
    [OtherIncomeEnum.ALIMONY]: i18n._(`incomeType.alimony`),
    [OtherIncomeEnum.EMPLOYMENT_EXPENSE]: i18n._(`incomeType.employment`),
    [OtherIncomeEnum.OTHER]: i18n._(`other`),
});

export const INCOME_TYPE_LABEL = (i18n: I18n) => ({
    SALARIED: i18n._(`Salaried`),
    HOURLY: i18n._(`Hourly`),
    HOURLY_COMMISSIONS: i18n._(`Hourly with commission`),
    COMMISSIONS: i18n._(`Commission`),
    SELF_EMPLOYED: i18n._(`Self employed`),
    PENSION: i18n._(`Pension`),
    NONE: i18n._(`No income / Parental leave / Sabbatical`),
});

export const TENURE_LABEL = (i18n: I18n) => ({
    PERMANENT: i18n._(`permanent`),
    ON_PROBATION: i18n._(`onProbation`),
    ON_CONTRACT: i18n._(`onContract`),
});

export const EMPLOYMENT_TYPE_LABEL = (i18n: I18n) => ({
    FULL_TIME: i18n._(`fullTime`),
    PART_TIME: i18n._(`partTime`),
    SEASONAL: i18n._(`seasonal`),
});
export const LAST_THREE_YEARS_OPTIONS: SelectOptions<number> = [
    {
        value: new Date().getFullYear(),
        label: new Date().getFullYear(),
    },
    {
        value: subYears(new Date(), 1).getFullYear(),
        label: subYears(new Date(), 1).getFullYear(),
    },
    {
        value: subYears(new Date(), 2).getFullYear(),
        label: subYears(new Date(), 2).getFullYear(),
    },
];

export const HIDDEN_FLAGS = ['RENEWAL_BEFORE_MATURITY_DATE'];

export const FLAGS_LABELS = (i18n: I18n, applicantName = '') => ({
    GDS: i18n._(`flags.GDS`),
    REFINANCE_WITHIN_1_YEAR_OF_PURCHASE: i18n._(
        `flags.REFINANCE_WITHIN_1_YEAR_OF_PURCHASE`
    ),
    RENEWAL_BEFORE_MATURITY_DATE: i18n._(`flags.RENEWAL_BEFORE_MATURITY_DATE`),
    APPLICANT_INCOME_TYPE_SELF_EMPLOYED_LESS_THAN_2_YEARS: i18n._(
        `flags.APPLICANT_INCOME_TYPE_SELF_EMPLOYED_LESS_THAN_2_YEARS`
    ),
    APPLICANT_CONSUMER_PROPOSAL_OR_BANKRUPTCY_LAST_7_YEARS: i18n._({
        id: `flags.APPLICANT_CONSUMER_PROPOSAL_OR_BANKRUPTCY_LAST_7_YEARS`,
        values: { applicantName },
    }),
    APPLICANT_EMPLOYMENT_COVID_19_IMPACTED: i18n._({
        id: `flags.APPLICANT_EMPLOYMENT_COVID_19_IMPACTED`,
        values: { applicantName },
    }),
    APPLICANT_EMPLOYMENT_ON_PROBATION: i18n._({
        id: `flags.APPLICANT_EMPLOYMENT_ON_PROBATION`,
        values: { applicantName },
    }),
    APPLICANTS_2_OR_MORE_PROPERTIES: i18n._(
        `flags.APPLICANTS_2_OR_MORE_PROPERTIES`
    ),
    'APPLICANT_MARITAL_STATUS_MISSING_CO-APPLICANT': i18n._(
        `flags.APPLICANT_MARITAL_STATUS_MISSING_CO-APPLICANT`
    ),
    APPLICANTS_WITH_NO_MARITAL_STATUS: i18n._(
        `flags.APPLICANTS_WITH_NO_MARITAL_STATUS`
    ),
    APPLICANT_INCOME_TYPE_NON_SALARIED_LESS_THAN_2_YEARS: i18n._({
        id: `flags.APPLICANT_INCOME_TYPE_NON_SALARIED_LESS_THAN_2_YEARS`,
        values: { applicantName },
    }),
    CLOSING_DATE_IN_MORE_THAN_120_DAYS: i18n._(
        `flags.CLOSING_DATE_IN_MORE_THAN_120_DAYS`
    ),
    MATURITY_DATE_IN_MORE_THAN_120_DAYS: i18n._(
        `flags.MATURITY_DATE_IN_MORE_THAN_120_DAYS`
    ),
    APPLICANT_INCOME_FROM_SUPPORT_SOURCE: i18n._(
        `flags.APPLICANT_INCOME_FROM_SUPPORT_SOURCE`
    ),
    APPLICANT_INCOME_INCREASE_MORE_THAN_20_PERCENT: i18n._({
        id: `flags.APPLICANT_INCOME_INCREASE_MORE_THAN_20_PERCENT`,
        values: { applicantName },
    }),
    APPLICANT_MULTIPLE_EMPLOYMENTS_IN_PAST_3_YEARS: i18n._({
        id: `flags.APPLICANT_MULTIPLE_EMPLOYMENTS_IN_PAST_3_YEARS`,
        values: { applicantName },
    }),
});

export const MAX_WIDTH_LAYOUT = 1000;

export const APPLICANTS_COLORS = [
    '#45b6fe',
    '#5E64FF',
    '#296d98',
    '#7CD6FD',
    '#FFB46F',
];

export const SectionsValues = {
    applicants: 'applicants',
    income: 'employments',
    downPayment: 'assetsAndDownPayment',
    applicantProperties: 'ownedProperties',
    property: 'subjectProperty',
    liabilities: 'liabilities',
    mortgage: 'mortgageDetails',
    qualifyingDetails: 'qualifyingDetails',
};
export const CLOSING_DOC_LOCATION = (
    i18n: I18n
): SelectOptions<'CANADA' | 'QUEBEC'> => [
    {
        value: 'CANADA',
        label: i18n._(`Canada`),
    },
    {
        value: 'QUEBEC',
        label: i18n._(`Quebec`),
    },
];

export const COMPOUND_PERIOD_OPTIONS = (
    i18n: I18n
): SelectOptions<CompoundPeriod> => [
    {
        value: 'MONTHLY',
        label: i18n._(`frequency.mo`),
    },
    {
        value: 'SEMIANNUALLY',
        label: i18n._(`frequency.sa`),
    },
];

export const NEW_COMPOUND_PERIOD_OPTIONS = (
    i18n: I18n
): SelectOptionProps<CompoundPeriod>[] => [
    {
        value: 'MONTHLY',
        label: i18n._(`frequency.mo`),
    },
    {
        value: 'SEMIANNUALLY',
        label: i18n._(`frequency.sa`),
    },
];

export const RELATION_TO_MAIN_APPLICANT_OPTIONS = (
    i18n: I18n
): SelectOptions<RelationToMainApplicant> => [
    {
        value: 'SPOUSE',
        label: i18n._(`relationToMainApplicant.SPOUSE`),
    },
    {
        value: 'COMMON_LAW',
        label: i18n._(`relationToMainApplicant.COMMON_LAW`),
    },
    {
        value: 'RELATED_FAMILY_MEMBER',
        label: i18n._(`relationToMainApplicant.RELATED_FAMILY_MEMBER`),
    },
    {
        value: 'PARENT',
        label: i18n._(`relationToMainApplicant.PARENT`),
    },
    {
        value: 'CHILD',
        label: i18n._(`relationToMainApplicant.CHILD`),
    },
    {
        value: 'SIBLING',
        label: i18n._(`relationToMainApplicant.SIBLING`),
    },
    {
        value: 'GRAND_PARENT',
        label: i18n._(`relationToMainApplicant.GRAND_PARENT`),
    },
    {
        value: 'GRAND_CHILD',
        label: i18n._(`relationToMainApplicant.GRAND_CHILD`),
    },
    {
        value: 'OTHER',
        label: i18n._(`relationToMainApplicant.OTHER`),
    },
];

export const LIABILITY_PAYMENT_MULTIPLIER_OPTIONS = (
    i18n: I18n
): SelectOptions<LiabilityPaymentMultiplier> => [
    // empty string -> isClearable
    {
        value: '3_PERCENT',
        label: i18n._(`3_PERCENT`),
    },
    {
        value: '5_PERCENT',
        label: i18n._(`5_PERCENT`),
    },
    {
        value: 'BOC_25_YEARS',
        label: i18n._(`BOC_25_YEARS`),
    },
];

export const LIABILITY_PAYOFF_MAP = {
    [LiabilityPayOffTypesEnum.FROM_PROCEEDS]: {
        id: LiabilityPayOffTypesEnum.FROM_PROCEEDS,
        labelKey: 'FROM_PROCEEDS',
    },
    [LiabilityPayOffTypesEnum.PRIOR_TO_ADVANCE]: {
        id: LiabilityPayOffTypesEnum.PRIOR_TO_ADVANCE,
        labelKey: 'PRIOR_TO_ADVANCE',
    },
    [LiabilityPayOffTypesEnum.FROM_SALE_PROCEEDS]: {
        id: LiabilityPayOffTypesEnum.FROM_SALE_PROCEEDS,
        labelKey: 'FROM_SALE_PROCEEDS',
    },
};

export const LIABILITY_CURRENCY_OPTIONS = (
    i18n: I18n
): SelectOptions<LiabilityCurrency> => [
    {
        value: 'CAD',
        label: i18n._(`CAD`),
    },
    {
        value: 'USD',
        label: i18n._(`USD`),
    },
];

export const LIABILITY_CREDIT_TYPE = (
    i18n: I18n
): SelectOptions<LiabilityCreditType> => [
    {
        value: LiabilityCreditTypeEnum.INSTALMENT,
        label: i18n._(`liability.creditType.instalment`),
    },
    {
        value: LiabilityCreditTypeEnum.MORTGAGE,
        label: i18n._(`liability.creditType.mortgage`),
    },
    {
        value: LiabilityCreditTypeEnum.OPEN,
        label: i18n._(`liability.creditType.open`),
    },
    {
        value: LiabilityCreditTypeEnum.REVOLVING,
        label: i18n._(`liability.creditType.revolving`),
    },
];

export const LIABILITY_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<LiabilityType> => [
    {
        value: 'CREDIT_CARD',
        label: i18n._(`CREDIT_CARD`),
    },
    {
        value: 'PERSONAL_LOAN',
        label: i18n._(`PERSONAL_LOAN`),
    },
    {
        value: 'STUDENT_LOAN',
        label: i18n._(`STUDENT_LOAN`),
    },
    //
    {
        value: 'AUTO_LOAN',
        label: i18n._(`AUTO_LOAN`),
    },
    {
        value: 'AUTO_LEASE',
        label: i18n._(`AUTO_LEASE`),
    },
    {
        value: 'SECURED_LINE_OF_CREDIT',
        label: i18n._(`SECURED_LINE_OF_CREDIT`),
    },
    {
        value: 'UNSECURED_LINE_OF_CREDIT',
        label: i18n._(`UNSECURED_LINE_OF_CREDIT`),
    },
    {
        value: 'ALIMONY',
        label: i18n._(`ALIMONY`),
    },
    {
        value: 'CHILD_SUPPORT',
        label: i18n._(`CHILD_SUPPORT`),
    },
    {
        value: 'INCOME_TAX',
        label: i18n._(`INCOME_TAX`),
    },
    {
        value: 'LEASE',
        label: i18n._(`LEASE`),
    },
    // TBD not suppoted for the moment
    // {
    //     value: 'MORTGAGE',
    //     label: i18n._(`MORTGAGE`),
    // },
    {
        value: 'OTHER',
        label: i18n._(`Other`),
    },
];

export const LIABILITY_TYPE_LABELS = (i18n: I18n) => ({
    CREDIT_CARD: i18n._(`CREDIT_CARD`),
    PERSONAL_LOAN: i18n._(`PERSONAL_LOAN`),
    STUDENT_LOAN: i18n._(`STUDENT_LOAN`),
    AUTO_LOAN: i18n._(`AUTO_LOAN`),
    AUTO_LEASE: i18n._(`AUTO_LEASE`),
    SECURED_LINE_OF_CREDIT: i18n._(`SECURED_LINE_OF_CREDIT`),
    UNSECURED_LINE_OF_CREDIT: i18n._(`UNSECURED_LINE_OF_CREDIT`),
    ALIMONY: i18n._(`ALIMONY`),
    CHILD_SUPPORT: i18n._(`CHILD_SUPPORT`),
    INCOME_TAX: i18n._(`INCOME_TAX`),
    LEASE: i18n._(`LEASE`),
    OTHER: i18n._(`Other`),
});

export const CREDITSCORE_QUALITY_OPTIONS = (
    i18n: I18n
): SelectOptions<CreditScoreQuality> => [
    {
        value: 'EXCELLENT',
        label: i18n._(`application.creditScoreQuality.excellent`),
    },
    {
        value: 'GOOD',
        label: i18n._(`application.creditScoreQuality.good`),
    },
    {
        value: 'FAIR',
        label: i18n._(`application.creditScoreQuality.fair`),
    },
    {
        value: 'POOR',
        label: i18n._(`application.creditScoreQuality.poor`),
    },
    {
        value: 'UNKNOWN',
        label: i18n._(`application.creditScoreQuality.unknown`),
    },
];

export const NUMBER_OF_APPLICANT_OPTIONS =
    (): SelectOptions<NumberOfApplicants> => [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3+', label: '3+' },
    ];

export const CalendlyConfig = {
    calendly: {
        default: {
            qc: {
                en: 'https://calendly.com/nesto-ca/qc-en',
                fr: 'https://calendly.com/nesto-ca/qc-fr',
            },
            on: {
                en: 'https://calendly.com/nesto-ca/on-en',
                fr: 'https://calendly.com/nesto-ca/on-fr',
            },
            bc: {
                en: 'https://calendly.com/nesto-ca/bc',
                fr: 'https://calendly.com/nesto-ca/bc',
            },
            ab: {
                en: 'https://calendly.com/nesto-ca/ab',
                fr: 'https://calendly.com/nesto-ca/ab',
            },
            default: {
                en: 'https://calendly.com/nesto-ca/on-en',
                fr: 'https://calendly.com/nesto-ca/qc-fr',
            },
        },
        confirmation: {
            qc: {
                en: 'https://calendly.com/nesto-ca/qc-en',
                fr: 'https://calendly.com/nesto-ca/qc-fr',
            },
            on: {
                en: 'https://calendly.com/nesto-ca/on-en',
                fr: 'https://calendly.com/nesto-ca/on-fr',
            },
            bc: {
                en: 'https://calendly.com/nesto-ca/bc',
                fr: 'https://calendly.com/nesto-ca/bc',
            },
            ab: {
                en: 'https://calendly.com/nesto-ca/ab',
                fr: 'https://calendly.com/nesto-ca/ab',
            },
            default: {
                en: 'https://calendly.com/nesto-ca/on-en',
                fr: 'https://calendly.com/nesto-ca/qc-fr',
            },
        },
        confirmationRenewRefinance: {
            qc: {
                en: 'https://calendly.com/nesto-ca/qc-en',
                fr: 'https://calendly.com/nesto-ca/qc-fr',
            },
            on: {
                en: 'https://calendly.com/nesto-ca/on-en',
                fr: 'https://calendly.com/nesto-ca/on-fr',
            },
            bc: {
                en: 'https://calendly.com/nesto-ca/bc',
                fr: 'https://calendly.com/nesto-ca/bc',
            },
            ab: {
                en: 'https://calendly.com/nesto-ca/ab',
                fr: 'https://calendly.com/nesto-ca/ab',
            },
            default: {
                en: 'https://calendly.com/nesto-ca/on-en',
                fr: 'https://calendly.com/nesto-ca/qc-fr',
            },
        },
        sdrFlowConfirmation: {
            qc: {
                en: 'https://calendly.com/nesto-ca/qc-en-ext',
                fr: 'https://calendly.com/nesto-ca/qc-fr-ext',
            },
            on: {
                en: 'https://calendly.com/nesto-ca/on-en-ext',
                fr: 'https://calendly.com/nesto-ca/on-fr-ext',
            },
            bc: {
                en: 'https://calendly.com/nesto-ca/bc-ext',
                fr: 'https://calendly.com/nesto-ca/bc-ext',
            },
            ab: {
                en: 'https://calendly.com/nesto-ca/ab-ext',
                fr: 'https://calendly.com/nesto-ca/ab-ext',
            },
            default: {
                en: 'https://calendly.com/nesto-ca/on-en-ext',
                fr: 'https://calendly.com/nesto-ca/qc-fr-ext',
            },
        },
    },
};

export const DOCUMENTS_STEP_OPTIONS = (
    i18n: I18n
): SelectOptions<DocumentStep> => [
    {
        label: i18n._('documentStep.critical'),
        value: DocumentStep.Critical,
    },
    {
        label: i18n._('documentStep.additional'),
        value: DocumentStep.Additional,
    },
];

export const DOCUMENTS_STATE_OPTIONS = (
    i18n: I18n
): SelectOptions<DocState> => [
    {
        label: i18n._('DOCUMENT_EMPTY'),
        value: 'DOCUMENT_EMPTY',
    },
    {
        label: i18n._('DOCUMENT_RECEIVED'),
        value: 'DOCUMENT_RECEIVED',
    },
    {
        label: i18n._('DOCUMENT_BROKER_APPROVED'),
        value: 'DOCUMENT_BROKER_APPROVED',
    },
    {
        label: i18n._('DOCUMENT_BROKER_UNAPPROVED'),
        value: 'DOCUMENT_BROKER_UNAPPROVED',
    },
    {
        label: i18n._('DOCUMENT_LENDER_ACCEPTED'),
        value: 'DOCUMENT_LENDER_ACCEPTED',
    },
    {
        label: i18n._('DOCUMENT_DISABLED'),
        value: 'DOCUMENT_DISABLED',
    },
];

export const FREQUENCY_LABEL = (i18n: I18n) => ({
    ANNUALLY: i18n._(`frequency.yr`),
    SEMIANNUALLY: i18n._(`frequency.sa`),
    QUARTERLY: i18n._(`frequency.quarterly`),
    MONTHLY: i18n._(`frequency.mo`),
    SEMIMONTHLY: i18n._(`frequency.sm`),
    BIWEEKLY: i18n._(`frequency.bw`),
    ACCELERATED_BIWEEKLY: i18n._(`frequency.acceleratedBiWeekly`),
    WEEKLY: i18n._(`frequency.wkly`),
    ACCELERATED_WEEKLY: i18n._(`frequency.acceleratedWeekly`),
    DAILY: i18n._(`frequency.daily`),
});

export const DASHBOARD_ROUTE_BY_ROLE: Record<TokenRole, string> = {
    borrower: '/',
    broker: '/',
    realtor: '/partner/applications',
    financialadvisor: '/partner/applications',
    advisor: '/partner/applications',
};

export const BOOST_EMAIL_TARGET = {
    ADVISOR: 'BROKER',
    PROCESSOR: 'OTHER',
} as const;

export const IG_BOOST_EMAIL_TARGET = {
    ESCALATION: 'ESCALATION',
    ESCALATION_WITH_DOCUMENTS: 'ESCALATION_WITH_DOCUMENTS',
} as const;

export type BoostEmailTarget = typeof BOOST_EMAIL_TARGET &
    typeof IG_BOOST_EMAIL_TARGET;

export type BoostEmailTargetValues = BoostEmailTarget[keyof BoostEmailTarget];

export const NESTO_BOOST_EMAIL_SELECT = (i18n: I18n) => [
    { value: BOOST_EMAIL_TARGET.ADVISOR, label: i18n._(`advisorBoostEmail`) },
    {
        value: BOOST_EMAIL_TARGET.PROCESSOR,
        label: i18n._(`financingBoostEmail`),
    },
];

export const IG_BOOST_EMAIL_SELECT = (i18n: I18n) => [
    {
        value: IG_BOOST_EMAIL_TARGET.ESCALATION,
        label: i18n._(`escalationBoostEmail`),
    },
    {
        value: IG_BOOST_EMAIL_TARGET.ESCALATION_WITH_DOCUMENTS,
        label: i18n._(`escalationBoostEmailWithDocuments`),
    },
];

export const ACTIVE_CHANGE_APPLICATION_TYPE_STATES = [
    'CREATED',
    'SUBMITTED',
    'UNDER_REVISION',
    'REVIEWED',
    'NOTES_SUBMITTED',
    'LENDER_SUBMITTED',
];

export const SDR_SECTIONS = [
    'IDENTIFICATION',
    'PROPERTY',
    'DOWN_PAYMENTS',
    'OWNED_PROPERTIES',
    'EMPLOYMENTS',
] as const;

export const SDR_HEADERS = (
    i18n: I18n
): Record<(typeof SDR_SECTIONS)[number], string> => ({
    IDENTIFICATION: i18n._(`applicants`),
    PROPERTY: i18n._(`subjectProperty`),
    EMPLOYMENTS: i18n._(`employments`),
    OWNED_PROPERTIES: i18n._(`ownedProperties`),
    DOWN_PAYMENTS: i18n._(`downPayment`),
});

export const EIGHTY_PERCENT = 0.8;
export const CLOSING_COST_MULTIPLIER = 0.015;
export const ZERO = 0;
export const DEFAULT_RATIO = 50;
export const ONE_HUNDRED = 100;

export const BADGE_TYPE = {
    CREATED: 'DOCUMENT_BROKER_APPROVED',
    SUBMITTED: 'DOCUMENT_BROKER_APPROVED',
    UNDER_REVISION: 'DOCUMENT_BROKER_APPROVED',
    REVIEWED: 'DOCUMENT_RECEIVED',
    NOTES_SUBMITTED: 'DOCUMENT_RECEIVED',
    LENDER_SUBMITTED: 'DOCUMENT_RECEIVED',
    LENDER_APPROVED: 'DOCUMENT_RECEIVED',
    PENDING_COMMITMENT_SIGNATURE: 'DOCUMENT_RECEIVED',
    PENDING_CONDITIONS: 'DOCUMENT_RECEIVED',
    COMPLETE: 'DOCUMENT_RECEIVED',
    NOTARY_ALERTED: 'DOCUMENT_RECEIVED',
    FUNDED: 'DOCUMENT_LENDER_ACCEPTED',
    CLOSED: 'DOCUMENT_LENDER_ACCEPTED',
    EXPIRED: 'DOCUMENT_BROKER_UNAPPROVED',
};

export const RESIDENCY_STATUS = (
    i18n: I18n
): SelectOptions<ResidencyStatus> => [
    {
        label: i18n._('permanent'),
        value: ResidencyStatus.PERMANENT,
    },
    {
        label: i18n._('temporary'),
        value: ResidencyStatus.TEMPORARY,
    },
];

export const APPLICANT_PERMISSIONS = ({
    i18n,
    isMainApplicant,
}: {
    i18n: I18n;
    isMainApplicant: boolean;
}): SelectOptions<ApplicantPermission> =>
    isMainApplicant
        ? [
              {
                  label: i18n._('MAIN_APPLICANT'),
                  value: 'MAIN_APPLICANT',
              },
          ]
        : [
              {
                  label: i18n._('CO_APPLICANT_DEFAULT'),
                  value: 'CO_APPLICANT_DEFAULT',
              },
          ];

export const PRESET_REJECTION_MESSAGES = [
    'presetMessage.rejectDocument.identifiedVersion',
    'presetMessage.rejectDocument.recentlyDated',
    'presetMessage.rejectDocument.signedVersion',
];

export const PROGRESS_ADVANCE_OPTIONS = (
    i18n: I18n
): SelectOptions<AdvanceType> => [
    {
        value: 'SINGLE ADVANCE',
        label: i18n._(`construction.advanceType.SINGLE_ADVANCE`),
    },
    {
        value: 'MULTIPLE ADVANCE',
        label: i18n._(`construction.advanceType.MULTIPLE_ADVANCE`),
    },
];

export const BUILDER_TYPE_OPTIONS = (
    i18n: I18n
): SelectOptions<BuilderType> => [
    {
        value: 'BUILDER',
        label: i18n._(`construction.builderType.BUILDER`),
    },
    {
        value: 'CONTRACTOR',
        label: i18n._(`construction.builderType.CONTRACTOR`),
    },
    {
        value: 'SELF_BUILD',
        label: i18n._(`construction.builderType.SELF_BUILD`),
    },
    {
        value: 'PRE_FABRICATED_HOMES',
        label: i18n._(`construction.builderType.PRE_FABRICATED_HOMES`),
    },
];

export const INITIAL_REVIEW_CATEGORIES = [
    'IDENTIFICATION',
    'CREDIT_BUREAU',
    'INCOME',
    'TARGET_PROPERTY',
    'VALIDATIONS',
    'QUALIFICATION',
    'APPROVAL',
] as UnderwriterChecklistState[];

export const SECOND_REVIEW_CATEGORIES = [
    'INFORMATION_FROM_SIGNED_DOCUMENTS',
    'DOWN_PAYMENT_VERIFICATION',
    'FINAL_REVIEW_BY_UW',
    'SECOND_REVIEW_BY_UW',
    'QA_REVIEW_AND_FINAL_APPROVAL',
] as UnderwriterChecklistState[];

export const DOCUMENTS_SECTIONS_FOR_CATEGORIES = {
    IDENTIFICATION: 'IDENTIFICATION',
    INCOME: 'INCOMES',
    TARGET_PROPERTY: 'PROPERTIES',
    INFORMATION_FROM_SIGNED_DOCUMENTS: 'FINAL_DOCUMENTS',
};

export const IG_TENANT_UW_FILTERS = [
    'UNDERWRITER_FINAL_CONVENTIONAL',
    'UNDERWRITER_FINAL_INSURANCE_CERTIFICATE',
    'UNDERWRITER_PROPERTY_HIGH_OR_BULK_LOW_RATIO',
    'UNDERWRITER_PROPERTY_BULK_LOW_RATIO',
    'UNDERWRITER_FINAL_INSURANCE_COVERAGE',
    'UNDERWRITER_PROPERTY_INSURANCE_CERTIFICATE',
];

export const BREAKOUT_VALUE = {
    XXS: 240,
    XS: 360,
    SM: 480,
    MD: 768,
    LG: 1024,
    XL: 1440,
    XXL: 1920,
};

export const LIEN_OPTIONS = (i18n: I18n): SelectOptions<LienOptions> => [
    {
        label: i18n._('firstLien'),
        value: 'FIRST_LIEN',
    },
    {
        label: i18n._('secondLien'),
        value: 'SECOND_LIEN',
    },
];

export const PRE_APPROVAL_TYPE = (
    i18n: I18n
): SelectOptions<PreApprovalType> => [
    {
        label: i18n._('preApproval.lite'),
        value: 'LITE',
    },
    {
        label: i18n._('preApproval.formal'),
        value: 'FORMAL',
    },
];

export const MAX_YEARS = 99;

export const MAX_MONTHS = 11;

export const MPI_INSURER_RESPONSE_OPTIONS = (
    i18n: I18n
): SelectOptions<MPIInsurerResponse> => [
    {
        label: i18n._('mpiInsurerResponse.yes'),
        value: MPIInsurerResponse.OPTED_IN,
    },
    {
        label: i18n._('mpiInsurerResponse.no'),
        value: MPIInsurerResponse.OPTED_OUT,
    },
    {
        label: i18n._('mpiInsurerResponse.ineligible'),
        value: MPIInsurerResponse.INELIGIBLE,
    },
];

export const NESTO_SUPPORT_LINK =
    'https://nestoca.atlassian.net/servicedesk/customer/portal/6';

export const APPLICATION_STATE_CHIP_VARIANT: Record<
    ApplicationState,
    ChipProps['variant']
> = {
    CREATED: 'warning',
    SUBMITTED: 'info',
    UNDER_REVISION: 'info',
    REVIEWED: 'info',
    NOTES_SUBMITTED: 'info',
    LENDER_SUBMITTED: 'info',
    LENDER_APPROVED: 'info',
    PENDING_COMMITMENT_SIGNATURE: 'info',
    PENDING_CONDITIONS: 'info',
    COMPLETE: 'info',
    NOTARY_ALERTED: 'info',
    FUNDED: 'success',
    CLOSED: 'default',
    EXPIRED: 'error',
};

export const PARTNER = {
    CANADA_LIFE: 'canada_life',
} as const;

export const PARTNER_LABEL = (i18n: I18n): Record<Partner, string> => ({
    [PARTNER.CANADA_LIFE]: i18n._('partner.canadaLife'),
});

export const SEGMENT = {
    // Canada Life Segments
    A: 'A',
    B1: 'B1',
    B2: 'B2',
    C: 'C',
    D: 'D',

    // IG Segments
    IGSM: 'IGSM',
} as const;

export const SEGMENT_LABEL = (i18n: I18n): Record<Segment, string> => ({
    [SEGMENT.A]: i18n._('segment.a'),
    [SEGMENT.B1]: i18n._('segment.b1'),
    [SEGMENT.B2]: i18n._('segment.b2'),
    [SEGMENT.C]: i18n._('segment.c'),
    [SEGMENT.D]: i18n._('segment.d'),
    [SEGMENT.IGSM]: i18n._('segment.IGSM'),
});

export const SEGMENT_OPTIONS = (
    i18n: I18n
): SelectOptions<keyof typeof SEGMENT> => [
    {
        value: 'A',
        label: SEGMENT_LABEL(i18n).A,
    },
    {
        value: 'B1',
        label: SEGMENT_LABEL(i18n).B1,
    },
    {
        value: 'B2',
        label: SEGMENT_LABEL(i18n).B2,
    },
    {
        value: 'C',
        label: SEGMENT_LABEL(i18n).C,
    },
    {
        value: 'D',
        label: SEGMENT_LABEL(i18n).D,
    },
];

export const QUALIFICATION_SCENARIO_HEADER = {
    'X-Validation-Scenario': 'QUALIFICATION_SCENARIO',
};

export const LIABILITY_STATUS_OPTIONS = (
    i18n: I18n
): SelectOptions<LiabilityStatus> => [
    {
        value: LIABILITY_STATUS.CURRENT,
        label: i18n._('liabilityStatus.current'),
    },
    {
        value: LIABILITY_STATUS.ARREARS,
        label: i18n._('liabilityStatus.arrears'),
    },
    {
        value: LIABILITY_STATUS.DEFAULT,
        label: i18n._('liabilityStatus.default'),
    },
];

export const PRODUCT_TRACKER_CODE_OPTIONS = (
    i18n: I18n
): SelectOptions<ProductTrackerCode> => [
    {
        value: PRODUCT_TRACKER_CODE.STANDARD,
        label: i18n._(`productTrackerCode.${PRODUCT_TRACKER_CODE.STANDARD}`),
    },
    {
        value: PRODUCT_TRACKER_CODE.ALT_A,
        label: i18n._(`productTrackerCode.${PRODUCT_TRACKER_CODE.ALT_A}`),
    },
    {
        value: PRODUCT_TRACKER_CODE.RENTAL,
        label: i18n._(`productTrackerCode.${PRODUCT_TRACKER_CODE.RENTAL}`),
    },
];
