import { addHeadersInterceptor } from '@nestoca/utils';
import axios, { type InternalAxiosRequestConfig } from 'axios';
import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

import { ClientReferralsImport } from 'components/canada-life-importer-card';
import { MortgageComparisonResponse } from 'components/chart/mortgage-compare';
import { WalnutLead } from 'components/closing-documents/walnut-insurance';
import { ServicingDataResponse } from 'components/export-servicing-data/servicing-data.interface';
import { InsurerSubmissionPayload } from 'components/insurer-submission';
import {
    InternalReferralPayload,
    InternalReferralResponse,
} from 'components/internal-referral/types';
import { MortgateComparisonRequest } from 'components/mortgage-tools/mortgage-compare/mortgage-compare';
import {
    PlaidMonitoringScreening,
    PlaidMonitoringHit,
    PlaidMonitoringScreeningInfo,
} from 'components/plaid-monitoring-card/types';
import { UnderwriterChecklistResponse } from 'types/checklist';
import {
    CoApplicantSearchParams,
    CoApplicantSearchPayload,
    CoApplicantSearchResponse,
} from 'types/co-applicant-search';
import {
    CMSPackageResponse,
    CMSPackageState,
    CMSPackagePayload,
    CMSPackageConditionsPayload,
    CMSPackageConditionsResponse,
    CMSPackageConditionPayload,
    CMSCreatePackageConditionResponse,
    CMSPackageCondition,
} from 'types/conditions-management-service';
import {
    DecisionEngineReport,
    DecisionEngineReportDetails,
} from 'types/decision-engine';
import { HelocApplication, HelocProductType } from 'types/heloc';
import { Referral } from 'types/referral';
import { type ServicingAsset } from 'types/servicing';
import {
    CustomFields,
    SubmissionNoteType,
    ValidateSubmissionNoteDocumentType,
} from 'types/submission-notes';

import type { TenantResponse } from '@nestoca/multi-tenant';
import type {
    BorrowerAccount,
    BrokerAccount,
    FinancialAdvisorAccount,
    SSOAuthCredentialsPayload,
    RealtorAuthResult,
    ForgotPasswordPayload,
    UpdatePasswordPayload,
    AuthResult,
    SubpartnerAccount,
} from 'providers/auth/types';
import type { AmountFrequency, UnderwriterChecklistPayload } from 'types';
import type { RegisteredAddress } from 'types/address';
import type {
    Agreement,
    AgreementRequestBody,
    Agreements,
    AgreementDocumentType,
    AgreementType,
} from 'types/agreement';
import type {
    Applicant,
    ApplicantAsset,
    ApplicantInfo,
    ApplicantSIN,
    CreditReportHistory,
    ExistingIncomeOther,
    IncomeEmployment,
    IncomeEmploymentPayload,
    IncomeOther,
    Liability,
    LiabilityFormData,
    PullCreditReport,
} from 'types/applicant';
import type {
    Application,
    ApplicationSummary,
    ApplicationEvent,
    ApplicationMortgage,
    ApplicationProduct,
    ApplicationType,
    FlagType,
    MortgageAmortization,
    UpdateApplicationMortgage,
    Segment,
    InsurerSubmissionCode,
} from 'types/application';
import type { ApplicationApplicantAccountMatch } from 'types/application-applicant-account-match';
import type { Asset, NewAsset } from 'types/asset';
import type { MortgagePaymentsCalculator } from 'types/calculator';
import type { ClosingDocumentLetterTemplateType } from 'types/closing-documents';
import type { Condition } from 'types/conditions';
import type {
    ApplicantDocument,
    ApplicantNewDocument,
    BoostEmailInfo,
    DocumentCounts,
    DocumentEvent,
    DocumentFile,
    DocumentFileMoveRequestBody,
    DocumentTypesResponse,
    DocumentValidationUpdate,
    DownloadableDocumentsResponse,
    EscalationEmailRequestBody,
    UploadFile,
    UploadFilePayload,
    DownloadClosingDocumentToDocCentrePayload,
    DocumentPDFUrl,
} from 'types/documents';
import type { InsurerResponse, InsurerSubmissionResponse } from 'types/insurer';
import type { MiddleOfficeSyncForm } from 'types/middle-office-sync';
import type {
    NotesType,
    NotesApplicationSectionType,
    NodeType,
} from 'types/notes';
import type { ApplicationValidation } from 'types/problems';
import type { ProductElegibility } from 'types/product';
import type { ProductSelectionQuestionsAnswer } from 'types/product-selection';
import type { Step, PayloadProductSelection } from 'types/product-selection';
import type {
    OtherProperty,
    SubjectProperty,
    TaxAuthorityParams,
    TaxAuthorityResponse,
} from 'types/property';
import type { Qualification } from 'types/qualification';
import type {
    HelocRateFilters,
    HelocRateFiltersResponse,
    Product,
    ProductsResponse,
    RateFilters,
    RateFiltersSimplified,
} from 'types/rates';
import type {
    PartnerApplicationsResponse,
    PartnerInvitationsResponse,
    SubPartnersCounts,
} from 'types/sub-partner';
import type { ClientInvitation } from 'types/sub-partner';

export type GetRequest = AxiosRequestConfig | null;

export type HEADERS = { [key: string]: string };

export const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

const isServer = typeof window === 'undefined';

const WALNUT_INSURANCE_API_KEY = !isServer
    ? window?.config?.walnutInsuranceApiKey
    : '1f57acaf3d58519c3h56';

type AccessToken = string;

export const create = (baseURL: string) => {
    const api = axios.create({
        baseURL,
        headers: {
            ...DEFAULT_HEADERS,
            // 'x-login-on-behalf': will be set later,
            // but before first API call
            // from the AccountRidProvider
        },
        timeout: 25000,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'comma' });
        },
    });

    api.interceptors.request.use((config) =>
        addHeadersInterceptor(config as InternalAxiosRequestConfig)
    );

    const setBackendBaseUrl = (url: string) => {
        api.defaults.baseURL = url;
    };

    const setXOnBehalf = (accountRid: string) => {
        api.defaults.headers['x-login-on-behalf'] = accountRid;
    };
    const resetXOnBehalf = () => {
        api.defaults.headers['x-login-on-behalf'] = undefined;
    };

    const apiGcs = axios.create({
        headers: {
            // ...DEFAULT_HEADERS,
        },
        timeout: 100000,
    });

    const setAuthToken = (accessToken: AccessToken) => {
        if (!accessToken) return;

        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    };

    const getAuthToken = () => api.defaults.headers.common['Authorization'];

    const getAccount = () => api.get<BorrowerAccount>('/account');

    const getBorrowerAccount = () => api.get<BorrowerAccount>('/borrower');

    const getBrokerAccount = () => api.get<BrokerAccount>(`/broker`);

    const getRealtorAccount = () => api.get<SubpartnerAccount>(`/realtor`);

    const getFinancialAdvisorAccount = () =>
        api.get<FinancialAdvisorAccount>(`/financialadvisor`);

    const loginRealtorSSO = ({
        realtorUid,
        timestamp,
        hash,
        partner,
    }: SSOAuthCredentialsPayload) =>
        api.post<RealtorAuthResult>(
            '/realtors/tokens/sso',
            {},
            {
                params: {
                    realtorUid,
                    // email,
                    timestamp,
                    hash,
                    partner,
                },
            }
        );

    // const login = (credentials: AuthCredentialsPayload) =>
    //     api.post<AuthResult>('/account/tokens', credentials, {
    //         headers: {
    //             // delete the default headers `Authorization`
    //             // this endpoint is to acquire a new token
    //             // so we dont need to send the old token or `undefined` value as string
    //             // Why? if we switch between nesto broker account and tenant broker account
    //             // the current logged in broker will return a 404 and get an `undefined` value as string
    //             Authorization: '',
    //         },
    //     });

    const forgotPassword = (params: ForgotPasswordPayload) =>
        api.post<{ status: number }>('/account/password/reset', params);

    const resetPassword = (id: number, params: UpdatePasswordPayload) =>
        api.put<AuthResult>(`/accounts/${id}/password`, params);

    const behalfLoginAccount = (params: any) =>
        api.post('/brokers/tokens/representations/account', params);

    const behalfLogoutAccount = (params: any) =>
        api.put('/brokers/tokens/representations/account', params);

    const getApplications = () => api.get<Application[]>('/applications');

    const getApplication = (id: number) =>
        api.get<Application>(`/applications/${id}`);

    const uploadFile = ({ applicationId, formData }: UploadFilePayload) =>
        api.post<UploadFile>(
            `/applications/${applicationId}/filogixocr/ocr`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );

    const getOcr = (applicationId: number) =>
        api.get<UploadFile>(`/applications/${applicationId}/filogixocr/data`);

    const getSubPartnerApplications = (
        filter: string,
        page: number,
        perPage: number
    ) =>
        api.get<PartnerApplicationsResponse>(
            `/subpartner/applications?filter=${filter.toUpperCase()}&page=${page}&limit=${perPage}`
        );

    const getInvitations = (
        registered: boolean,
        page: number,
        perPage: number
    ) =>
        api.get<PartnerInvitationsResponse>(
            `realtor/invites?registered=${registered}&page=${page}&limit=${perPage}`
        );

    const getRateCompare = (data: MortgateComparisonRequest[]) =>
        api.post<MortgageComparisonResponse[]>(
            'calculator/mortgage/compare',
            data
        );

    const getApplicationRates = (applicationId: number, params: RateFilters) =>
        api.get<ProductsResponse>(`/applications/${applicationId}/products`, {
            params,
        });

    const getRatesFilters = (applicationId: number) =>
        api.get<RateFilters>(`/applications/${applicationId}/products/filters`);

    const getHelocRatesFilters = (applicationId: number) =>
        api.get<HelocRateFiltersResponse>(
            `/applications/${applicationId}/heloc-filters`
        );

    const getHelocRates = (params: HelocRateFilters) =>
        api.get<HelocProductType[]>(`/products/heloc`, {
            params,
        });

    const getSelectedRate = (rateId) => api.get<Product>(`products/${rateId}`);

    const getSelectedHelocRate = (rateId) =>
        api.get<HelocProductType>(`products/heloc/${rateId}`);

    const getSubPartnerCounts = () =>
        api.get<SubPartnersCounts>(`/subpartner/counts`);

    const updateOcr = (applicationId: number, data: UploadFile) =>
        api.put<UploadFile>(
            `/applications/${applicationId}/filogixocr/data`,
            data
        );

    const confirmInsurer = (applicationId: number) =>
        api.post<InsurerResponse>(
            `/applications/${applicationId}/filogixocr/data/insurer`
        );

    const getInviteClients = () => api.get('/subpartner/invites');

    const inviteClient = (data: ClientInvitation) =>
        api.post('/subpartner/invites', {
            firstName: data.firstName,
            lastName: data.lastName,
            regionCode: data.province,
            phoneNumber: data.phoneNumber,
            email: data.email,
            language: data.language,
        });

    const getDocuments = (
        applicationId: number,
        applicantId: number,
        params?: { step: number }
    ) =>
        api.get<ApplicantDocument[]>(
            `/applications/${applicationId}/applicants/${applicantId}/documents`,
            {
                params,
            }
        );

    const getConditions = (applicationId: number) =>
        api.get<Condition[]>(`/applications/${applicationId}/conditions`);

    const updateCondition = (applicationId: number, condition: Condition) =>
        api.put<Condition>(
            `/applications/${applicationId}/conditions`,
            condition
        );

    const createDocument = (document: ApplicantNewDocument) =>
        api.post(
            `/applications/${document.applicationId}/applicants/${document.applicantId}/documents`,
            document
        );

    const getDocumentsCounts = (
        applicationId: number,
        params?: { step: number }
    ) =>
        api.get<DocumentCounts>(
            `/applications/${applicationId}/documents/counts`,
            { params }
        );

    const updateDocumentValidation = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        data: DocumentValidationUpdate
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/validation`,
            data
        );

    const updateDocumentState = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        data: { event: DocumentEvent }
    ) =>
        api.patch(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/event`,
            data
        );

    const acquireDocumentPDFUrl = ({
        applicationId,
        applicantId,
        documentType,
        year = 0,
        entityId = 0,
    }: ApplicantDocument) =>
        api.get<DocumentPDFUrl>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/download`,
            {
                params: {
                    forceDownload: true,
                    convert: true,
                },
            }
        );

    // Get all files merged into 1 PDF
    const getDocumentPreviewUrl = ({
        applicationId,
        applicantId,
        documentType,
        year = 0,
        entityId = 0,
    }: ApplicantDocument) =>
        api.get<DocumentPDFUrl>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/download`,
            {
                params: {
                    forceDownload: false,
                    convert: true,
                },
            }
        );

    // Get 1 file into a document
    const getDocumentPreviewFileUrl = (
        fileId: string,
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument
    ) =>
        api.get<DocumentPDFUrl>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}/download`,
            {
                params: {
                    forceDownload: false,
                    convert: true,
                },
            }
        );

    const acquireDocumentFileSignedUrl = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        data: any
    ) =>
        api.put<DocumentPDFUrl>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/upload`,
            data
        );

    const getDocumentFileUrl = (
        fileId: string,
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument
    ) =>
        api.get<DocumentPDFUrl>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}/download`
        );

    const uploadDocumentFile = (signerUrl: string, file: File) =>
        apiGcs.put(signerUrl, file, {
            headers: {
                // 'Content-Type': file.type // Backend is not creating the gcs object with this
                'Content-Type': '',
            },
        });

    const createDocumentFile = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        data: any
    ) =>
        api.post<Omit<DocumentPDFUrl, 'url'>>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files`,
            data
        );

    const deleteDocumentFile = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        fileId: string
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}`
        );

    const moveDocumentFile = (
        {
            applicationId,
            applicantId,
            documentType,
            year,
            entityId,
            fileId,
        }: ApplicantDocument & Pick<DocumentFile, 'fileId'>,
        data: DocumentFileMoveRequestBody
    ) =>
        api.patch(
            `applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}/move`,
            data
        );

    const updateDocumentFile = (
        {
            applicationId,
            applicantId,
            documentType,
            year,
            entityId,
            fileId,
        }: ApplicantDocument & Pick<DocumentFile, 'fileId'>,
        data: DocumentFile
    ) =>
        api.patch(
            `applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}`,
            data
        );

    const getDocumentTypes = (applicationId: number, applicantId: number) =>
        api.get<DocumentTypesResponse>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/types`
        );

    type DocumentMessageText = {
        text: string;
    };
    const createDocumentMessage = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        data: DocumentMessageText
    ) =>
        api.post<DocumentMessageText>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/messages`,
            data
        );

    const updateDocumentMessage = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        messageId: number,
        data: DocumentMessageText
    ) =>
        api.put<DocumentMessageText>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/messages/${messageId}`,
            data
        );

    const deleteDocumentMessage = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        messageId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/messages/${messageId}`
        );

    const getUnderwriterChecklist = (applicationId: number) =>
        api.get<UnderwriterChecklistResponse[]>(
            `/applications/${applicationId}/checklist/UNDERWRITER`
        );

    const updateUnderwriterChecklist = (
        applicationId: number,
        data: UnderwriterChecklistPayload
    ) =>
        api.put<UnderwriterChecklistResponse[]>(
            `/applications/${applicationId}/checklist`,
            data
        );

    const createDocumentRationale = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        data: DocumentMessageText
    ) =>
        api.post<DocumentMessageText>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/rationales`,
            data
        );

    const deleteDocumentRationale = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: ApplicantDocument,
        messageId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/rationales/${messageId}`
        );

    const getDownloadableDocuments = (applicationId: number) =>
        api.get<DownloadableDocumentsResponse>(
            `/applications/${applicationId}/documents/download`,
            {
                timeout: 32000,
            }
        );

    const sendDocumentBoostEmail = (
        applicationId: number,
        applicantId: number,
        params: string
    ) =>
        api.post<BoostEmailInfo>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/reminders`,
            params
        );

    const sendIGEscalationEmail = (
        applicationId: number,
        params: EscalationEmailRequestBody
    ) =>
        api.post<BoostEmailInfo>(
            `/applications/${applicationId}/escalation`,
            params
        );

    const getApplicantDocumentBoostEmail = (
        applicationId: number,
        applicantId: number
    ) =>
        api.get<BoostEmailInfo[]>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/reminders`
        );

    const getApplicationSummary = (applicationId: number) =>
        api.get<ApplicationSummary>(`/applications/${applicationId}/summary`);

    const getApplicationReviewSummaryPDF = (applicationId: number) =>
        api.get<Blob>(`/applications/${applicationId}/download`, {
            headers: { accept: 'application/pdf' },
            responseType: 'blob',
        });

    const getQualification = (applicationId: number) =>
        api.get<Qualification>(`/applications/${applicationId}/qualification`);

    const updateQualification = (
        applicationId: number,
        params: Qualification
    ) => api.put(`/applications/${applicationId}/qualification`, params);

    const updateAccount = (data: Partial<BorrowerAccount>) => {
        return api.put<BorrowerAccount>('/account', data);
    };

    const updateBroker = (data: BrokerAccount) => {
        return api.put<BrokerAccount>('/broker', data);
    };

    const updateApplicantInfo = (
        applicationId: number,
        applicantId: number,
        params: ApplicantInfo
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}`,
            params
        );

    const createCoapplicant = (
        applicationId: number,
        coapplicant: ApplicantInfo
    ) =>
        api.post<Applicant>(
            `/applications/${applicationId}/applicants`,
            coapplicant
        );

    const updateApplicantSIN = (
        applicationId: number,
        applicantId: number,
        params: ApplicantSIN
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/sin`,
            params
        );

    const deleteCoapplicant = (applicationId: number, applicantId: number) =>
        api.delete(`/applications/${applicationId}/applicants/${applicantId}`);

    const createRegisteredAddress = (
        applicationId: number,
        applicantId: number,
        params: RegisteredAddress
    ) =>
        api.post<RegisteredAddress>(
            `/applications/${applicationId}/applicants/${applicantId}/addresses`,
            params
        );

    const deleteRegisteredAddress = (
        applicationId: number,
        applicantId: number,
        addressId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/addresses/${addressId}`
        );

    const updateRegisteredAddress = (
        applicationId: number,
        applicantId: number,
        addressId: number,
        params: RegisteredAddress
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/addresses/${addressId}`,
            params
        );

    const createAsset = (
        applicationId: number,
        applicantId: number,
        params: NewAsset,
        headers?: Record<string, string>
    ) =>
        api.post<Asset>(
            `/applications/${applicationId}/applicants/${applicantId}/assets`,
            params,
            { headers }
        );

    const updateAsset = (
        applicationId: number,
        applicantId: number,
        assetId: number,
        params: ApplicantAsset,
        headers?: Record<string, string>
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/assets/${assetId}`,
            params,
            { headers }
        );

    const deleteAsset = (
        applicationId: number,
        applicantId: number,
        assetId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/assets/${assetId}`
        );

    const createOtherProperty = (
        applicationId: number,
        applicantId: number,
        params: OtherProperty,
        headers?: Record<string, string>
    ) => {
        return api.post<OtherProperty>(
            `/applications/${applicationId}/applicants/${applicantId}/properties`,
            params,
            { headers }
        );
    };

    const updateOtherProperties = (
        applicationId: number,
        otherPropertyId: number,
        applicantId: number,
        params: OtherProperty,
        headers?: Record<string, string>
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/properties/${otherPropertyId}`,
            params,
            { headers }
        );

    const deleteOtherProperty = (
        applicationId: number,
        otherPropertyId: number,
        applicantId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/properties/${otherPropertyId}`
        );

    const createApplicantOtherIncome = (
        applicationId: number,
        applicantId: number,
        params: IncomeOther
    ) =>
        api.post<IncomeOther>(
            `/applications/${applicationId}/applicants/${applicantId}/income/others`,
            params
        );

    const updateApplicantOtherIncome = (
        applicationId: number,
        applicantId: number,
        extraIncomeId: number,
        params: ExistingIncomeOther
    ) =>
        api.put<ExistingIncomeOther>(
            `/applications/${applicationId}/applicants/${applicantId}/income/others/${extraIncomeId}`,
            params
        );

    const deleteApplicantOtherIncome = (
        applicationId: number,
        applicantId: number,
        extraIncomeId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/income/others/${extraIncomeId}`
        );

    const updateSubjectProperty = (
        applicationId: number,
        params: Partial<SubjectProperty>,
        headers?: Record<string, string>
    ) =>
        api.put(`/applications/${applicationId}/property`, params, { headers });

    const updateEmployment = (
        applicationId: number,
        applicantId: number,
        employmentId: number,
        params: IncomeEmploymentPayload,
        headers?: Record<string, string>
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/income/employments/${employmentId}`,
            params,
            { headers }
        );

    const createEmployment = (
        applicationId: number,
        applicantId: number,
        params: IncomeEmploymentPayload,
        headers?: Record<string, string>
    ) =>
        api.post<IncomeEmployment>(
            `/applications/${applicationId}/applicants/${applicantId}/income/employments`,
            params,
            { headers }
        );

    const deleteEmployment = (
        applicationId: number,
        applicantId: number,
        employmentId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/income/employments/${employmentId}`
        );

    const createApplication = (type: ApplicationType, subPartnerId: number) =>
        api.post('/applications', { type, subPartnerId, enableMulti: true });

    const createApplicationNote = (
        applicationId: number,
        nodeType: 'general' | 'submission',
        text: { text: string }
    ) => api.post(`/applications/${applicationId}/notes/${nodeType}`, text);

    const createNote = (
        applicationId: number,
        nodeType: NodeType,
        resource: 'property',
        text: { text: string }
    ) =>
        api.post(
            `/applications/${applicationId}/notes/${nodeType}/${resource}`,
            text
        );

    const createNoteApplicant = (
        applicationId: number,
        applicantId: number,
        nodeType: NodeType,
        resource: NotesApplicationSectionType,
        text: { text: string }
    ) =>
        api.post(
            `/applications/${applicationId}/notes/${nodeType}/applicants/${applicantId}/${resource}`,
            text
        );

    const getNotes = (applicationId: number) =>
        api.get<NotesType>(`/applications/${applicationId}/notes`);

    const updateFlags = (applicationId: number, flag: FlagType) =>
        api.put(`/applications/${applicationId}/flags`, flag);

    const getBridgeLoanLetterPDF = (
        applicationId: number,
        template: ClosingDocumentLetterTemplateType = 'canada_en'
    ) =>
        api.get<Blob>(
            `/applications/${applicationId}/bridgeloan?template=${template}`,
            {
                headers: { accept: 'application/pdf' },
                responseType: 'blob',
            }
        );

    const getClosingDocumentLetterPDF = (
        applicationId: number,
        template?: ClosingDocumentLetterTemplateType
    ) =>
        api.get<Blob>(
            `/applications/${applicationId}/preapproval?template=${template}`,
            {
                headers: { accept: 'application/pdf' },
                responseType: 'blob',
            }
        );

    const getApplicationMortgage = (applicationId: number) =>
        api.get<ApplicationMortgage>(`applications/${applicationId}/mortgage`);

    const updateApplicationMortgage = (
        applicationId: number,
        data: UpdateApplicationMortgage
    ) =>
        api.put<ApplicationMortgage>(
            `applications/${applicationId}/mortgage`,
            data
        );

    const getApplicationMortgageAmortization = (applicationId: number) =>
        api.get<MortgageAmortization>(
            `applications/${applicationId}/mortgage/amortization`
        );

    const getMortgagePayments = (data: MortgagePaymentsCalculator) =>
        api.put<AmountFrequency[]>('calculator/mortgage-payments', data);

    const updateApplicationProduct = (
        applicationId,
        params: {
            productId?: number;
            amortization?: number;
            snapshotOverride?: ApplicationProduct;
        }
    ) => api.put(`/applications/${applicationId}/products`, params);

    const updateApplicationHelocProduct = (
        applicationId,
        params: {
            productId: number;
        }
    ) => api.put(`/applications/${applicationId}/products/heloc`, params);

    const updateApplicationState = (
        applicationId: number,
        event: ApplicationEvent
    ) => api.patch(`/applications/${applicationId}`, { event });

    const updateApplicationType = (
        applicationId: number,
        type: ApplicationType
    ) => api.put(`/applications/${applicationId}/type/${type}`);

    const createLiability = (
        applicationId: number,
        applicantId: number,
        data: LiabilityFormData
    ) =>
        api.post<Liability>(
            `/applications/${applicationId}/applicants/${applicantId}/liabilities`,
            data
        );

    const updateLiability = (
        applicationId: number,
        applicantId: number,
        liabilityId: number,
        data: LiabilityFormData
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/liabilities/${liabilityId}`,
            data
        );

    const deleteLiability = (
        applicationId: number,
        applicantId: number,
        liabilityId: number
    ) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/liabilities/${liabilityId}`
        );

    const uploadFilogixSyncPDF = (applicationId: number, pdfFile: File) =>
        api.post<MiddleOfficeSyncForm>(
            `/applications/${applicationId}/sync/filogix/pdf`,
            pdfFile,
            {
                headers: { 'Content-Type': 'application/pdf' },
            }
        );

    const setOtherIncomesSpecified = (
        applicationId: number,
        applicantId: number,
        params: { specified: boolean }
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/other-incomes-specified`,
            params
        );

    const setPropertiesSpecified = (
        applicationId: number,
        applicantId: number,
        params: { specified: boolean }
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/properties-specified`,
            params
        );

    const getProductSelectionState = (applicationId: number) =>
        api.get<ProductSelectionQuestionsAnswer>(
            `/applications/${applicationId}/selection/state`
        );

    // Agreements
    const getAgreements = (applicationId: number) =>
        api.get<Agreements>(`/applications/${applicationId}/agreements`);

    const createAgreement = (
        applicationId: number,
        data: AgreementRequestBody
    ) => api.post<Agreement>(`/applications/${applicationId}/agreements`, data);

    const createNewAgreement = (
        applicationId: number,
        language: string,
        type: AgreementType
    ) =>
        api.post(`/applications/${applicationId}/agreements/prefilled`, {
            lang: language,
            type,
        });

    const getAgreement = (applicationId: number, agreementId: number) =>
        api.get<Agreement>(
            `/applications/${applicationId}/agreements/${agreementId}`
        );

    const updateAgreement = (
        applicationId: number,
        agreementId: number,
        data: AgreementRequestBody
    ) =>
        api.put<Agreement>(
            `/applications/${applicationId}/agreements/${agreementId}`,
            data
        );

    const uploadAgreementDocument = (
        applicationId: number,
        agreementId: number,
        documentType: AgreementDocumentType,
        formData: FormData
    ) =>
        api.post<Agreement>(
            `/applications/${applicationId}/agreements/${agreementId}/upload/${documentType}`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );

    const sendAgreement = (
        applicationId: number,
        agreementId: number,
        emails: {
            emailsToCC: string[];
        }
    ) =>
        api.post<Agreement>(
            `/applications/${applicationId}/agreements/${agreementId}/send`,
            emails
        );

    const pullCreditReports = (
        applicationId: number,
        applicantId: number,
        params: PullCreditReport
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/creditreports`,
            params
        );

    const getCreditDecisionLogic = (application_id: number, language = 'en') =>
        api.get(`/applications/${application_id}/verify/credit`, {
            params: {
                lang: language,
            },
        });

    const getCreditReportsHistory = (applicantId: number) =>
        api.get<CreditReportHistory[]>(`/reports/${applicantId}/history`);

    const getReadableCreditReport = (creditReportId: number) =>
        api.get<Blob>(`/reports/history/${creditReportId}/readable`, {
            headers: { accept: 'application/pdf' },
            responseType: 'blob',
        });

    const getLoanAgreementPDF = (
        applicationId: number,
        template: ClosingDocumentLetterTemplateType = 'en'
    ) =>
        api.get<Blob>(`/applications/${applicationId}/loan-agreement`, {
            params: {
                lang: template,
            },
            headers: { accept: 'application/pdf' },
            responseType: 'blob',
        });

    const getHelocLoanAgreementPDF = (
        applicationId: number,
        template: ClosingDocumentLetterTemplateType = 'en'
    ) =>
        api.get<Blob>(`/applications/${applicationId}/loan-agreement/heloc`, {
            params: {
                lang: template,
            },
            headers: { accept: 'application/pdf' },
            responseType: 'blob',
        });

    const getNoticeOfCreditChargesPDF = (
        applicationId: number,
        template: ClosingDocumentLetterTemplateType = 'en'
    ) =>
        api.get<Blob>(
            `/applications/${applicationId}/notice-of-credit-charges`,
            {
                params: { lang: template },
                headers: { accept: 'application/pdf' },
                responseType: 'blob',
            }
        );

    const getUnsignedCommitmentLetterPDF = (
        applicationId: number,
        template: ClosingDocumentLetterTemplateType = 'en'
    ) =>
        api.get<Blob>(`/applications/${applicationId}/commitments-letter`, {
            params: { lang: template },
            headers: { accept: 'application/pdf' },
            responseType: 'blob',
        });

    const getCreditReportsPDF = (applicationId: number, applicantId: number) =>
        api.get<Blob>(
            `/applications/${applicationId}/reports/${applicantId}/readable`,
            {
                headers: { accept: 'application/pdf' },
                responseType: 'blob',
            }
        );

    const getEidReportsPDF = (applicationId: number, applicantId: number) =>
        api.get<Blob>(
            `/applications/${applicationId}/identitycompares/${applicantId}/readable`,
            {
                headers: { accept: 'application/pdf' },
                responseType: 'blob',
            }
        );

    const getFilogixAPISyncData = (
        applicationId: number,
        filogixId: string
    ): Promise<AxiosResponse<MiddleOfficeSyncForm>> =>
        api.post<MiddleOfficeSyncForm>(
            `applications/${applicationId}/sync/filogix/api/${filogixId}`
        );

    const confirmFilogixApplicationId = (
        applicationId: number,
        filogixApplicationId: string
    ) =>
        api.put(`/applications/${applicationId}/filogix-application-id`, {
            filogixApplicationId,
        });

    const getSubmissionCodesForInsurer = (
        applicationId: number,
        insurer: string
    ) =>
        api.get<InsurerSubmissionCode[]>(
            `/applications/${applicationId}/insurers/codes`,
            {
                params: {
                    insurer,
                },
            }
        );

    const submitToInsurer = (
        applicationId: number,
        insurerId: string,
        data: InsurerSubmissionPayload
    ) =>
        api.post<InsurerSubmissionResponse>(
            `/applications/${applicationId}/insurers/${insurerId}`,
            data,
            {
                timeout: 60000,
            }
        );

    const cancelInsurerSubmission = (
        applicationId: number,
        insurerId: string
    ) =>
        api.delete(`/applications/${applicationId}/insurers/${insurerId}`, {
            timeout: 60000,
        });

    const getInsurerSubmissionDetails = (
        applicationId: number,
        insurerId: string
    ) =>
        api.get<InsurerSubmissionResponse>(
            `/applications/${applicationId}/insurers/${insurerId}`
        );

    const getInsurerSubmissionHistory = (applicationId: number) =>
        api.get<InsurerSubmissionResponse[]>(
            `/applications/${applicationId}/insurers`
        );

    const finalizeFilogixSync = (applicationId: number) =>
        api.post(`applications/${applicationId}/sync/filogix/finalize`);

    const getServicingData = (applicationId: number) =>
        api.get<ServicingDataResponse>(
            `/applications/${applicationId}/loanservicing/external/deal`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

    const exportServicingData = (applicationId: number) =>
        api.post<ServicingDataResponse>(
            `/applications/${applicationId}/loanservicing/external/deal`,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

    const pushDealToServicing = (applicationId: number) =>
        api.post(`/applications/${applicationId}/loanservicing/deal`, null);

    const getNestoServicingAssets = (accountId: number) =>
        api.get<ServicingAsset[]>(
            `/v2/admin/borrowers/${accountId}/assets?includeInvestor=true`,
            null
        );

    const productSelection = (
        applicationId: number,
        params?: { availability: true }
    ) => api.get(`/applications/${applicationId}/selection`, { params });

    const productSelectionStep = (
        applicationId: number,
        stepId: Step,
        params: PayloadProductSelection
    ) =>
        api.put(
            `/applications/${applicationId}/selection/steps/${stepId}`,
            params
        );

    const productSelectionStepInfo = (applicationId: number, stepId: any) =>
        api.get(`/applications/${applicationId}/selection/steps/${stepId}`);

    const applicationValidation = (
        applicationId: number,
        scenario: 'ADVISOR_SCENARIO' | 'FULL_APPLICATION_SCENARIO'
    ) =>
        api.get<ApplicationValidation>(
            `/applications/${applicationId}/validate/${scenario}`
        );

    const lockApplication = (
        applicationId: number,
        params: { locked: boolean }
    ) => api.put(`/applications/${applicationId}/lock`, params);

    const getAllProducts = (params: RateFiltersSimplified) =>
        api.get<Product[]>('/products/all', { params });

    const getSubmissionNotes = (applicationId: number) =>
        api.get<SubmissionNoteType[]>(
            `/applications/${applicationId}/submission-notes`
        );

    const updateSubmissionNotes = (
        applicationId: number,
        noteId: number,
        values: CustomFields
    ) =>
        api.put(
            `/applications/${applicationId}/submission-notes/${noteId}/custom-fields`,
            values
        );

    const submitSubmissionNotes = (
        applicationId: number,
        noteId: string | number,
        values: {
            state: string;
            isTeamLead: boolean;
        }
    ) =>
        api.put(
            `/applications/${applicationId}/submission-notes/${noteId}/state`,
            values
        );

    const syncSubmissionNotes = (applicationId: number, noteId: any) =>
        api.put(
            `/applications/${applicationId}/submission-notes/${noteId}/sync`
        );
    const createSubmissionNote = (applicationId: number) =>
        api.post(
            `/applications/${applicationId}/submission-notes?ignore-pre-approval=false`
        );

    const createSubmissionNoteFromPreApproval = (applicationId: number) =>
        api.post(`/applications/${applicationId}/submission-notes`);

    const getSubmissionNote = (
        applicationId: number,
        submissionNoteId: number
    ) =>
        api.get<SubmissionNoteType>(
            `/applications/${applicationId}/submission-notes/${submissionNoteId}`
        );

    const submitDeededNotaryDocuments = (applicationId: number) =>
        api.put(`/applications/${applicationId}/deeded-export`);

    const validateDocuments = (applicationId: number) =>
        api.get<ValidateSubmissionNoteDocumentType>(
            `/applications/${applicationId}/submission-notes/documents/validate`
        );

    const getApplicationElegibility = (
        applicationId: number,
        productId: number,
        productVersion: number
    ) =>
        api.get<ProductElegibility>(
            `/applications/${applicationId}/decision/${productId}/${productVersion}/eligibility`
        );

    const updateApplicationOwner = (
        applicationId: number,
        applicantId: number
    ) =>
        api.put(`/applications/${applicationId}/applicants/${applicantId}/pad`);

    const getReferrals = (applicationId: number) =>
        api.get<Referral>(`/referrals/byapplicationid/${applicationId}`);

    const clientReferralsImport = (partner: 'cl', file: File) =>
        api.post<ClientReferralsImport>(
            `/client-referrals/import/${partner}`,
            file
        );

    const patchApplicationPreApprovalType = (
        applicationId: number,
        preApprovalType: 'LITE' | 'FORMAL'
    ) =>
        api.patch(
            `/applications/${applicationId}/preapproval/${preApprovalType}`
        );

    const getTenantById = (id: number) =>
        api.get<TenantResponse>(`/tenants/${id}`);

    const convertApplicationToGoLive = (applicationId: number) =>
        api.put(`/applications/${applicationId}/convert-go-live`);

    const setPortProperty = (
        applicationId: number,
        applicantId: number,
        propertyId: number
    ) =>
        api.post(
            `/applications/${applicationId}/applicants/${applicantId}/properties/${propertyId}/port`
        );

    const deletePortProperties = (applicationId: number, applicantId: number) =>
        api.delete(
            `/applications/${applicationId}/applicants/${applicantId}/properties/port`
        );

    const getHelocApplication = (applicationId: number) =>
        api.get<HelocApplication>(`/applications/${applicationId}/heloc`);

    const updateHelocApplication = (
        applicationId: number,
        payload: HelocApplication
    ) => api.put(`/applications/${applicationId}/heloc`, payload);

    const applicantCreditConsent = (
        applicationId: number,
        applicantId: number,
        payload: string | null
    ) =>
        api.put(
            `/applications/${applicationId}/applicants/${applicantId}/creditreports/consent`,
            { creditReportPullConsentDate: payload }
        );

    const deleteHelocApplication = (applicationId: number) =>
        api.delete(`/applications/${applicationId}/heloc`);

    const deleteTermLoanApplication = (applicationId: number) =>
        api.delete(`/applications/${applicationId}/mortgage`);

    const downloadClosingDocumentToDocumentCenter = (
        {
            applicationId,
            applicantId,
            documentType,
            year = 0,
            entityId = 0,
        }: DownloadClosingDocumentToDocCentrePayload,
        data: FormData
    ) =>
        api.post<void>(
            `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/save`,
            data,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );

    const getPlaidMonitoringScreenings = (
        applicationId: number,
        applicantId: number
    ) =>
        api.get<PlaidMonitoringScreening[]>(
            `/applications/${applicationId}/applicants/${applicantId}/screen/list`
        );

    const createPlaidMonitoringScreening = (
        applicationId: number,
        applicantId: number
    ) =>
        api.post<PlaidMonitoringScreeningInfo>(
            `/applications/${applicationId}/applicants/${applicantId}/screen`
        );

    const getPlaidMonitoringHitsByScreeningId = (
        applicationId: number,
        applicantId: number,
        screeningId: string
    ) =>
        api.get<PlaidMonitoringHit[]>(
            `/applications/${applicationId}/applicants/${applicantId}/screen/hits/${screeningId}`
        );

    const getApplicationApplicantAccountMatch = (applicationId: number) =>
        api.get<ApplicationApplicantAccountMatch>(
            `/applications/${applicationId}/applicants/check-account-match`
        );

    const getDecisionEngineReportDetails = (applicationId: number) =>
        api.get<DecisionEngineReportDetails>(
            `/decisions/reports/applications/${applicationId}/details`
        );

    const getDecisionEngineReport = (applicationId: number) =>
        api.get<DecisionEngineReport>(
            `/decisions/reports/applications/${applicationId}`
        );

    const updateCanadaLifeSegment = (applicationId: number, segment: Segment) =>
        api.patch<void>(`/applications/${applicationId}/segment`, {
            segment,
        });

    const updateLoanNumberAtReferral = (
        applicationId: number,
        loanNumberAtReferral: string
    ) =>
        api.put<void>(`/applications/${applicationId}/referral-loan-number`, {
            loanNumberAtReferral,
        });

    const createWalnutInsuranceLead = (data: WalnutLead) =>
        api.post<string>(
            'https://api.canadalistings.net/walnut/index.php',
            data,
            {
                headers: {
                    AUTHORIZATION: `Bearer ${WALNUT_INSURANCE_API_KEY}`,
                    'x-login-on-behalf': undefined,
                },
            }
        );

    const coApplicantSearch = (
        payload: CoApplicantSearchPayload,
        params?: CoApplicantSearchParams
    ) =>
        api.post<CoApplicantSearchResponse>(
            `/borrowers/similar-search`,
            payload,
            { params }
        );

    const createInternalReferral = (payload: InternalReferralPayload) =>
        api.post<InternalReferralResponse>('/applications/referral', payload);

    const getTaxAuthorities = (params: TaxAuthorityParams) =>
        api.get<TaxAuthorityResponse[]>('/v2/admin/tax/authorities', {
            params,
        });

    const updateApplicationIsPort = (applicationId: number, isPort: boolean) =>
        api.patch<void>(`/applications/${applicationId}/is-port`, {
            isPort,
        });

    const getCMSPackages = (states?: CMSPackageState[]) =>
        api.get<CMSPackageResponse[]>('/cms/packages', {
            params: { state: states },
        });

    const createCMSPackage = (payload: CMSPackagePayload) =>
        api.post<CMSPackageResponse>('/cms/package', payload);

    const getCMSPackageConditions = (
        packageId: string,
        payload: CMSPackageConditionsPayload
    ) =>
        api.post<CMSPackageConditionsResponse>(
            `/cms/package/${packageId}/conditions`,
            payload
        );

    const createCMSPackageCondition = (
        packageId: string,
        payload: CMSPackageConditionPayload
    ) =>
        api.post<CMSCreatePackageConditionResponse>(
            `/cms/package/${packageId}/condition`,
            payload
        );

    const getCMSPackageCondition = (packageId: string, conditionId: string) =>
        api.get<CMSPackageCondition>(
            `/cms/package/${packageId}/condition/${conditionId}`
        );

    const updateCMSPackageCondition = (
        packageId: string,
        conditionId: string,
        payload: CMSPackageConditionPayload
    ) =>
        api.put<void>(
            `/cms/package/${packageId}/condition/${conditionId}`,
            payload
        );

    const updateApplicationCMSPackageCondition = (
        applicationId: number,
        packageId: number
    ) =>
        api.put<void>(
            `/applications/${applicationId}/condition_set?conditionPackageID=${packageId}`
        );

    const updateCMSPackageState = (packageId: string, state: CMSPackageState) =>
        api.put<void>(`/cms/package/${packageId}/state?state=${state}`);

    const syncServicingLoanNumber = (applicationId: number) =>
        api.put<void>(`/applications/${applicationId}/servicing-referral-sync`);

    return {
        api,
        setXOnBehalf,
        resetXOnBehalf,
        setBackendBaseUrl,
        behalfLoginAccount,
        behalfLogoutAccount,
        // login,
        getRealtorAccount,
        loginRealtorSSO,
        forgotPassword,
        resetPassword,
        getAccount,
        getBorrowerAccount,
        getBrokerAccount,
        getFinancialAdvisorAccount,
        setAuthToken,
        getAuthToken,
        getApplications,
        getApplication,
        getInvitations,
        getSubPartnerApplications,
        getSubPartnerCounts,
        uploadFile,
        getOcr,
        updateOcr,
        confirmInsurer,
        getInviteClients,
        inviteClient,
        getSelectedRate,
        getSelectedHelocRate,
        getApplicationRates,
        getRatesFilters,
        getHelocRatesFilters,
        getHelocRates,
        getConditions,
        updateCondition,
        getDocuments,
        createDocument,
        getDocumentsCounts,
        updateDocumentValidation,
        updateDocumentState,
        acquireDocumentPDFUrl,
        getDocumentPreviewUrl,
        getDocumentPreviewFileUrl,
        acquireDocumentFileSignedUrl,
        getDocumentFileUrl,
        uploadDocumentFile,
        createDocumentFile,
        deleteDocumentFile,
        moveDocumentFile,
        updateDocumentFile,
        getDocumentTypes,
        updateAccount,
        updateBroker,
        updateApplicantInfo,
        createCoapplicant,
        updateApplicantSIN,
        deleteCoapplicant,
        createRegisteredAddress,
        deleteRegisteredAddress,
        updateRegisteredAddress,
        createAsset,
        updateAsset,
        deleteAsset,
        createOtherProperty,
        updateOtherProperties,
        deleteOtherProperty,
        createApplicantOtherIncome,
        updateApplicantOtherIncome,
        deleteApplicantOtherIncome,
        updateSubjectProperty,
        updateEmployment,
        createEmployment,
        deleteEmployment,
        createDocumentMessage,
        updateDocumentMessage,
        deleteDocumentMessage,
        createDocumentRationale,
        deleteDocumentRationale,
        getDownloadableDocuments,
        sendDocumentBoostEmail,
        sendIGEscalationEmail,
        getApplicantDocumentBoostEmail,
        getApplicationSummary,
        getApplicationReviewSummaryPDF,
        createNote,
        createNoteApplicant,
        createApplication,
        createApplicationNote,
        getNotes,
        updateFlags,
        getQualification,
        updateQualification,
        getBridgeLoanLetterPDF,
        getClosingDocumentLetterPDF,
        getApplicationMortgage,
        getApplicationMortgageAmortization,
        updateApplicationMortgage,
        getMortgagePayments,
        updateApplicationProduct,
        updateApplicationHelocProduct,
        updateApplicationState,
        createLiability,
        updateLiability,
        deleteLiability,
        uploadFilogixSyncPDF,
        setOtherIncomesSpecified,
        setPropertiesSpecified,
        getProductSelectionState,
        getAgreements,
        createAgreement,
        createNewAgreement,
        getAgreement,
        updateAgreement,
        uploadAgreementDocument,
        sendAgreement,
        pullCreditReports,
        getLoanAgreementPDF,
        getHelocLoanAgreementPDF,
        getNoticeOfCreditChargesPDF,
        getUnsignedCommitmentLetterPDF,
        getCreditDecisionLogic,
        getCreditReportsPDF,
        getCreditReportsHistory,
        getReadableCreditReport,
        getFilogixAPISyncData,
        confirmFilogixApplicationId,
        getSubmissionCodesForInsurer,
        submitToInsurer,
        cancelInsurerSubmission,
        getInsurerSubmissionDetails,
        getInsurerSubmissionHistory,
        finalizeFilogixSync,
        getServicingData,
        exportServicingData,
        productSelection,
        productSelectionStep,
        productSelectionStepInfo,
        getEidReportsPDF,
        applicationValidation,
        lockApplication,
        pushDealToServicing,
        updateApplicationType,
        getAllProducts,
        getRateCompare,
        getSubmissionNotes,
        updateSubmissionNotes,
        getSubmissionNote,
        createSubmissionNote,
        submitSubmissionNotes,
        syncSubmissionNotes,
        submitDeededNotaryDocuments,
        validateDocuments,
        getApplicationElegibility,
        getNestoServicingAssets,
        updateApplicationOwner,
        getReferrals,
        clientReferralsImport,
        getUnderwriterChecklist,
        updateUnderwriterChecklist,
        createSubmissionNoteFromPreApproval,
        patchApplicationPreApprovalType,
        getTenantById,
        convertApplicationToGoLive,
        setPortProperty,
        deletePortProperties,
        getHelocApplication,
        updateHelocApplication,
        applicantCreditConsent,
        deleteHelocApplication,
        deleteTermLoanApplication,
        downloadClosingDocumentToDocumentCenter,
        getPlaidMonitoringScreenings,
        getPlaidMonitoringHitsByScreeningId,
        createPlaidMonitoringScreening,
        getApplicationApplicantAccountMatch,
        getDecisionEngineReportDetails,
        getDecisionEngineReport,
        updateCanadaLifeSegment,
        updateLoanNumberAtReferral,
        createWalnutInsuranceLead,
        coApplicantSearch,
        createInternalReferral,
        getTaxAuthorities,
        updateApplicationIsPort,
        getCMSPackages,
        createCMSPackage,
        getCMSPackageConditions,
        createCMSPackageCondition,
        getCMSPackageCondition,
        updateCMSPackageCondition,
        updateCMSPackageState,
        updateApplicationCMSPackageCondition,
        syncServicingLoanNumber,
    };
};

// eslint-disable-next-line
export const logMonitor = (res: AxiosError) => {
    // const { ok, status, duration, problem, config, headers } = res;

    // const isBlocklistedStatusCode =
    //     status && blockListedErrorCodes.includes(status);
    // const isBlocklistedReason =
    //     res.data &&
    //     res.data.reason &&
    //     blockListedReasons.includes(res.data.reason);

    // if (!ok && !isBlocklistedStatusCode && !isBlocklistedReason) {
    //     log({
    //         error: `Http Error (${status || problem}): ${problem}`,
    //         tag: 'http-error',
    //         data: {
    //             status,
    //             problem,
    //             ok,
    //             duration,
    //             url: config && config.url,
    //             method: config && config.method,
    //             requestId: headers && headers[`x-request-id`],
    //             authenticated: !!(config && config.headers[`Authorization`]),
    //         },
    //     });
    // }

    return;
};
